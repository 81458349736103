/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import useServices from '../../../Hooks/useServices'
ChartJS.register(ArcElement, Tooltip, Legend)

export function PieChar ({ lotes, finca }) {
  const { landsU } = useServices()
  const [lands, setLands] = useState(null)
  const [hectareas, setHectareas] = useState(null)

  useEffect(() => {
    if (finca[0]) {
      const getLotes = async () => {
        const lotes = await landsU(finca[0].nid)
        lotes &&
          setLands(
            lotes.map(
              (lote) =>
                `${lote.field_name} ${parseInt(
                  (Number(lote.field_hectares) /
                    Number(finca[0].field_hectares)) *
                    100
                )} %`
            )
          )
        lotes && setHectareas(lotes.map((lote) => lote.field_hectares))
      }
      getLotes()
    }
  }, [finca, lotes])
  const data = {
    labels: lands && lands,
    datasets: [
      {
        label: '# of Votes',
        data: hectareas && hectareas,
        backgroundColor: [
          '#6aa84f',
          '#a2c48f',
          '#8cc276',
          '#d0f0c0',
          '#e8f5dc',
          '#d9ead3'
        ],
        borderColor: [
          '#e8f5dc',
          '#d9ead3',
          '#7bb55d',
          '#c3a3ff',
          '#85b18c',
          '#64a041'
        ],
        borderWidth: 1
      }
    ]
  }
  const options = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: '#000',
          font: {
            size: 20
          }
        }
      }
    }
  }
  return (
    <Pie
      options={options}
      onLoad={() => alert('hola')}
      style={{ width: '300px', height: '300px' }}
      data={data}
    />
  )
}
