import React, { useContext, useEffect, useState } from 'react'
import './InfoFarm.scss'
import { string } from 'prop-types'
import { userFarmContex } from '../../../App'

const InfoFarm = ({ nameFarm, direccionFarm, areaFarm }) => {
  const farmContex = useContext(userFarmContex)
  const [hidden, setHidden] = useState(true)
  useEffect(() => {
    if (farmContex.length > 0) {
      if (farmContex[0].field_hectares === '' || farmContex[0].field_hectares === '') {
        setHidden(false)
      } else {
        setHidden(true)
      }
    }
  }, [farmContex])
  return (
    <>
    {hidden && <div className="info__farm">
      <div className="info__farm--name">
        <h2>{farmContex.length > 0 && farmContex[0].field_name}</h2>
      </div>
      <div className="info__farm--location">
        {farmContex.length > 0
          ? (
          <p>
            <span>{farmContex[0].field_ubication}</span> /{' '}
            <span>{farmContex[0].field_hectares} Hectáreas</span>
          </p>
            )
          : (
          <p>
            <span>---</span> /{' '}
            <span>---- ----</span>
          </p>
            )}
      </div>
    </div> }
    </>

  )
}

InfoFarm.propTypes = {
  nameFarm: string,
  direccionFarm: string,
  areaFarm: string
}

export default InfoFarm
