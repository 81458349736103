import React from 'react'
import HeaderLeft from '../../molecules/HeaderLeft/HeaderLeft'
import HeaderCenter from '../../molecules/HeaderCenter/HeaderCenter'
import HeaderRight from '../../molecules/HeaderRight/HeaderRight'
import { array, bool } from 'prop-types'
import './Header.scss'

const Header = ({ farm, reporteHidden }) => {
  return (
    <div className="header">
      <HeaderLeft reporteHidden={reporteHidden} />
      <HeaderCenter />
      <HeaderRight farm={farm} />
    </div>
  )
}

Header.propTypes = {
  farm: array,
  reporteHidden: bool
}
export default Header
