import swal from 'sweetalert'

const getInterpretation = async (nid) => {
  const req = await fetch(
    `${process.env.REACT_APP_API}/calculate/interpretation/${nid}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  if (req.status === 200) {
    const res = await req.json()
    return res
  } else {
    swal('Algo ha salido mal', 'interpretación', 'error')
  }
}

export default getInterpretation
