const getLand = async (nid) => {
  const req = await fetch(
    `${process.env.REACT_APP_API}/list/${nid}/land`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  const res = await req.json()
  window.localStorage.setItem('Land', JSON.stringify(res))
  return res
}

export default getLand
