import React, { useState, useEffect } from 'react'
import Spinner from '../../atoms/Spinner/Spinner'
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Polygon,
  GroundOverlay
} from '@react-google-maps/api'
import { array, object, string, bool, number, oneOfType, func } from 'prop-types'
import './Map.scss'
import swal from 'sweetalert'
import useBounds from '../../../Hooks/useBounds'

const Map = ({
  urlImagep,
  analysis,
  center,
  geoJson,
  geoJsonLand,
  imgNutrient,
  imgNutrientCompare,
  farmContex,
  eyeValueSel1,
  eyeValueSel2,
  eyeValueSel3,
  eyeValueSel4,
  geoJsonLandAll,
  spinner,
  setimagenMedidas,
  googleApiKey
}) => {
  const [arrayLand, setArrayLand] = useState(null)
  const [arrayLandCrops, setArrayLandCrops] = useState(null)
  const [bounds, setBounds] = useState(null)
  const [newCenter, setNewCenter] = useState(null)
  const [zoomMap, setZoomMap] = useState()
  const { farmBounds } = useBounds()
  const mapTypeId = 'satellite'
  const containerStyle = {
    width: '100%',
    height: '65vh'
  }
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApiKey
  })

  useEffect(() => {
    if (geoJsonLandAll) {
      if (geoJsonLandAll.length > 0) {
        const arrayLandAll = geoJsonLandAll.map((land) =>
          land.field_outlineland.length > 0 &&
          JSON.parse(
            land.field_outlineland.replace(
              /(['"])?([a-z0-9A-Z_]+)(['"])?:/g,
              '"$2": '
            )
          )
        )
        setArrayLandCrops(arrayLandAll)
      } else {
        setArrayLandCrops('')
      }
    }
  }, [geoJsonLandAll])

  useEffect(() => {
    if (farmContex[0]) {
      const rawBounds = farmContex[0].field_array_sentinel && farmBounds(JSON.parse(farmContex[0].field_array_sentinel))
      setimagenMedidas(rawBounds)
      if (rawBounds) {
        setBounds(rawBounds)
        setNewCenter({
          lat: (rawBounds.north + rawBounds.south) / 2,
          lng: (rawBounds.east + rawBounds.west) / 2
        })
        setZoomMap(parseInt((rawBounds.north - rawBounds.south) * 1000))
      }
    }
  }, [farmContex])

  useEffect(() => {
    if (geoJsonLand) {
      const ctl = geoJsonLand.replace(
        /(['"])?([a-z0-9A-Z_]+)(['"])?:/g,
        '"$2": '
      )
      setArrayLand(JSON.parse(ctl))
    } else {
      setArrayLand([])
    }
  }, [geoJsonLand])

  const ct = geoJson && geoJson.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": ')
  const arrayOutline = ct && JSON.parse(ct)
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null)

  const onUnmount = React.useCallback(function callback (map) {
    setMap(null)
  }, [])

  const onLoad = (e) => {
    const wrapper = document.createElement('div')

    for (const key in e) {
      if (key !== 'Punto' && key !== 'Latitud' && key !== 'Longitud') {
        wrapper.innerHTML += `<p> <span>${key}:</span> ${e[key]} </p>`
      }
    }
    swal({
      title: 'Analisis del punto' + ' ' + e.Punto,
      content: wrapper,
      button: 'X',
      className: 'modal-analysis'
    })
  }

  // Init Map
  const options = {
    fillColor: '#66000000',
    fillOpacity: 1,
    strokeColor: 'white',
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  }
  const options2 = {
    fillColor: '#70CFE3',
    fillOpacity: 0.2,
    strokeColor: '#70CFE3',
    strokeOpacity: 1,
    strokeWeight: 3,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  }
  const urlIcon = `${process.env.REACT_APP_API}/sites/default/files/2023-01/icon.png`
  // eslint-disable-next-line multiline-ternary
  return isLoaded ? (
    <div className="map">
      {!spinner.hidden && <Spinner text={spinner.text} />}
      <div className="google__map">
        {bounds
          ? <GoogleMap
        mapContainerStyle={containerStyle}
        center={newCenter}
        zoom={zoomMap <= 10 ? 16 : 15}
        // onLoad={onload}
        onUnmount={onUnmount}
        mapTypeId={mapTypeId}
      >
        {eyeValueSel1 && (
          <GroundOverlay
            bounds={bounds}
            key={imgNutrient + '--key1'}
            url={imgNutrient && imgNutrient}
            opacity={1}
          />
        )}
        {eyeValueSel2 && (
          <GroundOverlay
            key={imgNutrientCompare + '--key2'}
            url={imgNutrientCompare && imgNutrientCompare}
            bounds={bounds}
            opacity={1}
          />
        )}
        {eyeValueSel3 && (
          <GroundOverlay
            opacity={1}
            key={urlImagep}
            url={urlImagep && urlImagep}
            bounds={bounds}
          />
        )}
        <Polygon paths={arrayOutline && arrayOutline} options={options} />
        <Polygon paths={arrayLand && arrayLand} options={options2} />
        {arrayLandCrops && (
          <Polygon paths={arrayLandCrops} options={options2} />
        )}
        {eyeValueSel4 && analysis !== 1
          ? analysis.map((e, i) => (
              <Marker
                key={i}
                position={{ lat: Number(e.Latitud), lng: Number(e.Longitud) }}
                icon={urlIcon}
                onClick={() => onLoad(e)}
              />
          ))
          : null}
      </GoogleMap>
          : <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat: 4.570868,
          lng: -74.297333
        }}
        zoom={14.5}
        // onLoad={onload}
        onUnmount={onUnmount}
        mapTypeId={mapTypeId}
      >
      </GoogleMap>
        }
      </div>
    </div>
  ) : (
    <></>
  )
}

Map.propTypes = {
  analysis: oneOfType([number, array]),
  center: object,
  geometry: object,
  geoJson: string,
  visibleana: bool,
  geoJsonLand: string,
  urlImagep: string,
  farmContex: array,
  imgNutrient: string,
  imgNutrientCompare: string,
  eyeValueSel1: bool,
  eyeValueSel2: bool,
  eyeValueSel3: bool,
  eyeValueSel4: bool,
  geoJsonLandAll: array,
  spinner: object,
  setimagenMedidas: func,
  googleApiKey: string
}

export default Map
