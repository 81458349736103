import getCrop from '../services/getCrop'
import getSentinelDate from '../services/getSentinelDate'
import getVegeIndex from '../services/getVegeIndex'
import getWeather from '../services/getWeather'
import getToken from '../services/getToken'
import getStatistics from '../services/getStatistics'
import getAnalysis from '../services/getAnalysis'
import getAnalysisList from '../services/getAnalysisList'
import getInterpretation from '../services/getInterpretation'
import getFarm from '../services/getFarm'
import getLand from '../services/getLand'
import getGoogleKey from '../services/getGoogleKey'

const useServices = () => {
  const farmsU = async (uid) => getFarm(uid)
  const landsU = async (nid) => getLand(nid)
  const cropTypes = async () => getCrop()
  const vegeIndex = async () => getVegeIndex()
  const sentinelDates = async (token, date) => getSentinelDate(token, date)
  const weather = async (fId) => getWeather(fId)
  const tokenSentinel = async () => getToken()
  const getAnalysisApi = async (nid) => getAnalysis(nid)
  const getAnalysisListApi = async (nid) => getAnalysisList(nid)
  const getInterpretationApi = async (nid) => getInterpretation(nid)
  const keyGoogle = async () => getGoogleKey()
  const statisticsSentinel = async (
    arrayC,
    token,
    startDate,
    endDate,
    interval,
    script,
    cloudCoverage
  ) =>
    getStatistics(
      arrayC,
      token,
      startDate,
      endDate,
      interval,
      script,
      cloudCoverage
    )

  return {
    cropTypes,
    vegeIndex,
    sentinelDates,
    weather,
    tokenSentinel,
    statisticsSentinel,
    getAnalysisApi,
    getAnalysisListApi,
    getInterpretationApi,
    farmsU,
    landsU,
    keyGoogle
  }
}

export default useServices
