import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import FormLogin from '../../atoms/FormLogin/FormLogin'
import Logo from '../../atoms/Logo/Logo'

import './Login.scss'

const Login = () => {
  const navigate = useNavigate()
  useEffect(() => {
    Boolean(window.localStorage.getItem('Token')) && navigate('/home')
  }, [])

  return (
    <div className="login">
      <div className="login__left"></div>
      <div className="login__right">
        <Logo />
        <FormLogin />
      </div>
    </div>
  )
}

export default Login
