import React from 'react'
import HiddenShow from '../HiddenShow/HiddenShow'
import { func, bool } from 'prop-types'
import './ShowAnalysis.scss'

const ShowAnalysis = ({ eyeValueSel4, setEyeValueSel4, inputDisable }) => {
  return (
    <div className="select__farm select__eye show--analysis">
      <HiddenShow
        eyeValueSel1={eyeValueSel4}
        setEyeValueSel1={setEyeValueSel4}
      />
      <h2 className='select__farm--title'>Análisis</h2>
    </div>
  )
}

ShowAnalysis.propTypes = {
  setEyeValueSel4: func,
  eyeValueSel4: bool,
  inputDisable: bool
}
export default ShowAnalysis
