function formatCoordinates (coordinates) {
  if (!coordinates || !coordinates.length) {
    return 'No hay coordenadas disponibles.'
  }

  return coordinates
    .map((coordinate) => `${coordinate.lat},${coordinate.lng}`)
    .join('|')
}
export default formatCoordinates
