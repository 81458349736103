import React from 'react'
import Logo from '../../atoms/Logo/Logo'
import NavBar from '../NavBar/NavBar'
import './HeaderLeft.scss'
import { bool } from 'prop-types'

const HeaderLeft = ({ reporteHidden }) => {
  return (
    <div className='header__left'>
        <Logo/>
        <NavBar reporteHidden={reporteHidden}/>
    </div>
  )
}

HeaderLeft.propTypes = {
  reporteHidden: bool
}
export default HeaderLeft
