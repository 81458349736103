import getBounds from '../helpers/getBounds'

const useBounds = () => {
  const farmBounds = (rawData) => getBounds(rawData)

  return {
    farmBounds
  }
}

export default useBounds
