import React, { useState, useEffect, createRef } from 'react'
import DatePicker from 'react-datepicker'
import { array, number, func, bool, string } from 'prop-types'
import './Statistics.scss'
import useServices from '../../../Hooks/useServices'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import LineChart from '../../atoms/LineChart/LineChart'
import weatherVar from '../../../helpers/weatherVar'
import { PieChar } from '../../atoms/PieChart/PieChart'
import { PieChar2 } from '../../atoms/PieChart2/PieChart2'
import validarObjeto from '../../../helpers/objectValidate'
// import Spinner from '../../atoms/Spinner/Spinner'

const Statistics = ({
  vegIndex,
  farmContex,
  cloudCoverage,
  selectedIndex,
  inputDisable,
  setinputDisable,
  image,
  takeScreenShot,
  image2,
  takeScreenShot2,
  landContex
}) => {
  const date1 = new Date()
  const date = new Date()
  const [startDate, setStartDate] = useState(
    new Date(date1.setMonth(date.getMonth() - 3))
  )
  const [endDate, setEndDate] = useState(date)
  const [forecastStart] = useState(new Date())
  const [forecastEnd] = useState(
    new Date(new Date().setDate(new Date().getDate() + 5))
  )
  const [forecast, setForecast] = useState([])
  const forecasrArray = []
  const { tokenSentinel, statisticsSentinel, weather } = useServices()
  const [info, setInfo] = useState()
  const [loader, setLoader] = useState(false)
  const [loader2] = useState(false)
  const [weatherVars, setWeatherVars] = useState(null)
  const [theVarWeather, setTheVarWeather] = useState(null)
  // defaultValue Selected

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )
  useEffect(() => {
    // logica para obtener las fechas del pronostico tomando la fecha actual
    while (forecastEnd.getTime() >= forecastStart.getTime()) {
      forecastStart.setDate(forecastStart.getDate() + 1)
      forecasrArray.push(
        forecastStart.getFullYear() +
          '/' +
          (forecastStart.getMonth() + 1) +
          '/' +
          forecastStart.getDate()
      )
    }
    setForecast(forecasrArray)
  }, [])

  useEffect(() => {
    const getStatistics = async () => {
      if (farmContex[0]) {
        if (farmContex[0].field_array_sentinel) {
          setLoader(true)
          const arrayC = JSON.parse(farmContex[0].field_array_sentinel)
          const dataIn = selectedIndex ? selectedIndex[0].name : 'NDVI'
          // peticion api para obter el token
          const { res: rawToken } = await tokenSentinel()
          const token = rawToken.data.access_token
          // peticion a la api de sentinel para traer los datos estadisticos de los indices
          const staSentinel = await statisticsSentinel(
            arrayC,
            token,
            startDate.toJSON().slice(0, 10),
            endDate.toJSON().slice(0, 10),
            1,
            dataIn,
            cloudCoverage
          )
          const labelsData = staSentinel.res.data.map((e) =>
            e.interval.to.slice(0, 10)
          )
          const indice = staSentinel.res.data.map(
            (e) => e.outputs.data.bands.B0.stats.mean
          )
          // a qui se va a aguardar los otros datos de la api del clima, los de los dias pasados !!!
          const arrayTemporal = indice.map((e) => null)
          const { nextDays_data: rawWeather } = await weather(farmContex[0].nid)
          if (validarObjeto(rawWeather)) {
            const arrayWeatherVar = rawWeather.filter((e, i) => i > 0 && i <= 6 && e)
            // funcion que devuelve un objeto con las variables del clima
            setWeatherVars(weatherVar(arrayTemporal, arrayWeatherVar))
            // se actualiza el estado info con los datos que seran usados en la grafica componente linechart
            setInfo({
              labelsData: labelsData.concat(forecast),
              indice,
              dataIn
            })
          } else {
            setInfo({
              labelsData: labelsData.concat(forecast),
              indice,
              dataIn
            })
          }
          setLoader(false)
        }
      }
    }
    farmContex.length > 0 && getStatistics()
  }, [farmContex, startDate, endDate, selectedIndex, cloudCoverage, forecast])
  const handleWeatherVars = (e) => {
    weatherVars && setTheVarWeather({ name: weatherVars[e].name, value: weatherVars[e][e] })
  }
  const ref = createRef(null)
  const getImage = () => takeScreenShot(ref.current)
  useEffect(() => {
    if (!loader) {
      setTimeout(() => {
        getImage()
        getImage2()
      }, 500)
    }
  }, [loader])

  const ref2 = createRef(null)
  const getImage2 = () => takeScreenShot2(ref2.current)
  return (
    <div className="statistics">
      <div className="statistics--left">
        <div className="statistics--left__data select--var">
          <span className="statistics--ld--text">Clima</span>
          <select
            disabled={inputDisable}
            onChange={(e) => handleWeatherVars(e.target.value)}
            className="select__farm--select"
          >
            <option value={1}>Seleccione</option>
            {weatherVars &&
              weatherVars.map((e, i) => (
                <option key={i} value={i}>
                  {e.name}
                </option>
              ))}
          </select>
        </div>
        <div className="statistics--left__data">
          <span className="statistics--ld--text">Desde</span>
          <DatePicker
            disabled={inputDisable}
            dateFormat={'MMM d, yyyy'}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            maxDate={endDate}
          />
        </div>
        <div className="statistics--left__data">
          <span className="statistics--ld--text">Hasta</span>
          <DatePicker
            disabled={inputDisable}
            dateFormat={'MMM d, yyyy'}
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            maxDate={new Date()}
          />
        </div>
      </div>
      <div ref={ref} className="statistics--right">
        <LineChart loader={loader} info={info} theVarWeather={theVarWeather} />
      </div>
      {landContex && (
        <div
          style={{
            position: 'absolute',
            top: '200vh',
            width: '900px',
            margin: '0 auto'
          }}
          ref={ref2}
          className="statistics--under"
        >
          <div
            style={{
              width: '420px',
              display: 'inline-block',
              marginRight: '60px'
            }}
          >
            <h2
              style={{
                color: 'black',
                fontSize: '25px',
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              Área de los cultivos en la Finca
            </h2>
            <PieChar2 lotes={landContex} finca={farmContex} loader2={loader2} />
            <p style={{ color: 'black', fontSize: '20px' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
          <div
            style={{
              width: '420px',
              display: 'inline-block',
              textAlign: 'center'
            }}
          >
            <h2
              style={{ color: 'black', fontSize: '25px', fontWeight: 'bold' }}
            >
              Área de los lotes en la Finca
            </h2>
            <PieChar lotes={landContex} finca={farmContex} loader2={loader2} />
            <p style={{ color: 'black', fontSize: '20px' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

Statistics.propTypes = {
  vegIndex: array,
  farmContex: array,
  cloudCoverage: number,
  selectedIndex: array,
  inputDisable: bool,
  setinputDisable: func,
  image: string,
  takeScreenShot: func,
  image2: string,
  takeScreenShot2: func,
  landContex: string
}

export default Statistics
