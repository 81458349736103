import React, { useEffect, useState, useContext } from 'react'
import Features from '../../organisms/Features/Features'
import Interpretation from '../../organisms/interpretation/Interpretation'
import Map from '../../organisms/Map/Map'
import Statistics from '../../organisms/Statistics/Statistics'
import StatisticsImage from '../../organisms/statisticsImage/StatisticsImage'
import useServices from '../../../Hooks/useServices'
import useLoggin from '../../../Hooks/useLoggin'
import { useScreenshot } from 'use-react-screenshot'
import { userFarmContex, userLandContex } from '../../../App'
import { func, object } from 'prop-types'
import './Home.scss'
import swal from 'sweetalert'

const Home = ({
  setLandContext,
  setFarm,
  setLand,
  setReporte,
  reporte,
  setReporteHidden
}) => {
  // const { analysis } = useGetAnalysisSoil()
  const [userFarm, setUserFarm] = useState(null)
  const [useCenter, setUseCenter] = useState(null)
  const [geoJson, setGeoJson] = useState(null)
  const [geoJsonLand, setGeoJsonLand] = useState(null)
  const [geoJsonLandAll, setGeoJsonLandAll] = useState(null)
  const [urlImagep, setUrlImagep] = useState(null)
  const [analysis, setAnalysis] = useState(null)
  const [visibleAna, setVisibleAna] = useState(false)
  const [imgNutrient, setImgNutrient] = useState(null)
  const [imgNutrientCompare, setImgNutrientCompare] = useState(null)
  const [cloudCoverage, setCloudCoverage] = useState(50)
  const [eyeValueSel1, setEyeValueSel1] = useState(true)
  const [eyeValueSel2, setEyeValueSel2] = useState(false)
  const [eyeValueSel3, setEyeValueSel3] = useState(true)
  const [eyeValueSel4, setEyeValueSel4] = useState(false)
  const [dateSentinel, setDateSentinel] = useState({})
  const [spinner, setSpinner] = useState({ hidden: true, text: 'text' })
  const [vegIndex, setVegIndex] = useState(null)
  const [useInterShow, setInterShow] = useState(false)
  const [interpretation, setInterpretation] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const farmContex = useContext(userFarmContex)
  const landContex = useContext(userLandContex)
  const { getAnalysisApi, keyGoogle } = useServices()
  const { userFarm: userFarmUpdate } = useLoggin()
  const [inputDisable, setinputDisable] = useState(false)
  const [imagenMedidas, setimagenMedidas] = useState(false)
  const [googleApiKey, setGoogleApiKey] = useState(null)
  const [image, takeScreenShot] = useScreenshot()
  const [image2, takeScreenShot2] = useScreenshot()
  // Default Colombia
  const center = {
    lat: 4.570868,
    lng: -74.297333
  }
  function validarObjeto (objeto) {
    const keys = Object.keys(objeto)
    for (const key of keys) {
      if (
        !Object.prototype.hasOwnProperty.call(objeto, key) ||
        objeto[key] == null ||
        objeto[key] === ''
      ) {
        return false
      }
    }
    return true
  }
  useEffect(() => {
    setReporte({
      ...reporte,
      imageSentinel: urlImagep,
      index: selectedIndex,
      cloudCoverage,
      image,
      dateSentinel,
      image2,
      imagenMedidas
    })
    validarObjeto(reporte) ? setReporteHidden(true) : setReporteHidden(false)
  }, [
    urlImagep,
    farmContex,
    selectedIndex,
    cloudCoverage,
    image,
    dateSentinel,
    image2,
    imagenMedidas
  ])
  useEffect(() => {
    if (landContex.length > 0) {
      setGeoJsonLand(landContex[0].field_outlineland)
    }
  }, [landContex])
  useEffect(() => {
    const reloadHome = async () => {
      if (JSON.parse(window.localStorage.getItem('Farm'))) {
        const fincas = await userFarmUpdate(
          JSON.parse(window.localStorage.getItem('uid'))
        )
        setUserFarm(fincas)
      } else {
        window.localStorage.clear()
        window.location.replace('/')
      }
    }
    const getGoogleApi = async () => {
      const req = await keyGoogle()
      setGoogleApiKey(req.googleApi)
    }
    getGoogleApi()
    reloadHome()
  }, [])
  useEffect(() => {
    if (userFarm !== null && userFarm !== undefined) {
      const geoJson = farmContex[0].field_geojson_outline
      try {
        setGeoJson(geoJson)
        center.lat = parseFloat(farmContex[0].field_latitude)
        center.lng = parseFloat(farmContex[0].field_length)
        setUseCenter(center)
        const getAnalysisFunc = async (nid) => {
          const raw = await getAnalysisApi(nid)
          raw.length === undefined ? setAnalysis(1) : setAnalysis(raw)
        }
        getAnalysisFunc(farmContex[0].nid)
      } catch (error) {
        swal('algo salio mal', `${error}`, 'info')
      }
    }
  }, [farmContex])

  return (
    <div className="page__home">
      <div
        className={`page__home--left ${
          useInterShow ? 'interpretation--on' : 'interpretation--off'
        }`}
      >
        {googleApiKey && (
          <Map
            googleApiKey={googleApiKey}
            setimagenMedidas={setimagenMedidas}
            spinner={spinner}
            geoJsonLandAll={geoJsonLandAll}
            farmContex={farmContex}
            urlImagep={urlImagep}
            analysis={analysis}
            visibleana={visibleAna}
            center={useCenter}
            geoJson={geoJson}
            geoJsonLand={geoJsonLand}
            imgNutrient={imgNutrient}
            imgNutrientCompare={imgNutrientCompare}
            eyeValueSel1={eyeValueSel1}
            eyeValueSel2={eyeValueSel2}
            eyeValueSel3={eyeValueSel3}
            eyeValueSel4={eyeValueSel4}
          />
        )}
        <StatisticsImage urlImagep={urlImagep} />
        <Statistics
          image={image}
          takeScreenShot={takeScreenShot}
          image2={image2}
          takeScreenShot2={takeScreenShot2}
          inputDisable={inputDisable}
          setinputDisable={setinputDisable}
          selectedIndex={selectedIndex}
          farmContex={farmContex}
          landContex={landContex}
          vegIndex={vegIndex}
          cloudCoverage={cloudCoverage}
        />
      </div>
      <div
        className={`page__home--right ${
          useInterShow ? 'interpretation--on' : 'interpretation--off'
        }`}
      >
        {useInterShow && (
          <Interpretation
            useInterShow={useInterShow}
            interpretation={interpretation}
            setInterShow={setInterShow}
          />
        )}
        <Features
          inputDisable={inputDisable}
          setinputDisable={setinputDisable}
          setVegIndex={setVegIndex}
          setSpinner={setSpinner}
          setGeoJsonLandAll={setGeoJsonLandAll}
          dateSentinel={dateSentinel}
          setDateSentinel={setDateSentinel}
          cloudCoverage={cloudCoverage}
          setCloudCoverage={setCloudCoverage}
          setLand={setLand}
          setLandContext={setLandContext}
          setFarm={setFarm}
          userFarm={userFarm}
          setUserFarm={setUserFarm}
          setUrlImagep={setUrlImagep}
          visibleAna={visibleAna}
          setVisibleAna={setVisibleAna}
          setImgNutrient={setImgNutrient}
          setImgNutrientCompare={setImgNutrientCompare}
          setInterShow={setInterShow}
          eyeValueSel1={eyeValueSel1}
          eyeValueSel2={eyeValueSel2}
          eyeValueSel3={eyeValueSel3}
          setEyeValueSel1={setEyeValueSel1}
          setEyeValueSel2={setEyeValueSel2}
          setEyeValueSel3={setEyeValueSel3}
          eyeValueSel4={eyeValueSel4}
          setEyeValueSel4={setEyeValueSel4}
          useInterShow={useInterShow}
          setInterpretation={setInterpretation}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
        />
      </div>
    </div>
  )
}

Home.propTypes = {
  setFarm: func,
  setLandContext: func,
  setLand: func,
  setGeoJsonLand: func,
  setReporte: func,
  reporte: object,
  setReporteHidden: func
}
export default Home
