import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useLoggin from '../../../Hooks/useLoggin'
import './FormLogin.scss'

const FormLogin = () => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate()
  const [user, setUser] = useState({})
  const { userLogin, userFarm } = useLoggin()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const userLogged = await userLogin(user)
    await userFarm(userLogged.res.current_user.uid)
    navigate('/home')
  }

  return (
    <div className="form__login">
      <form onSubmit={(e) => handleSubmit(e)} className="form__login--form">
        <input
          onChange={(e) => setUser({ ...user, name: e.target.value })}
          type="text"
          placeholder="Usuario"
        />
        <input
          onChange={(e) => setUser({ ...user, pass: e.target.value })}
          type="password"
          placeholder="Contraseña"
        />
        <button className="button-green form__login--form--login">Iniciar sesion</button>
      </form>
    </div>
  )
}

export default FormLogin
