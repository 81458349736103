import React from 'react'
import { func, bool } from 'prop-types'
import './HiddenShow.scss'
import srcshow from '../../../img/eyeshow.png'
import srchidden from '../../../img/eyehidden.png'

const HiddenShow = ({ setEyeValueSel1, eyeValueSel1 }) => {
  const handleEye = () => {
    setEyeValueSel1(!eyeValueSel1)
  }
  return (
    <img
      onClick={(e) => handleEye()}
      className="select__eye--img"
      src={eyeValueSel1 ? srcshow : srchidden}
    />
  )
}

HiddenShow.propTypes = {
  eyeValueSel1: bool,
  setEyeValueSel1: func
}

export default HiddenShow
