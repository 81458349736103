import sentinelIndex from '../helpers/sentinelIndex'
const getIndex = async (arrayC, cloudCoverage, date, token, script) => {
  const evalscript = sentinelIndex(script)
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append(
    'Authorization',
    `Bearer ${token}`
  )
  const raw = JSON.stringify({
    input: {
      bounds: {
        geometry: {
          type: 'Polygon',
          coordinates: [arrayC]
        }
      },
      data: [
        {
          dataFilter: {
            timeRange: {
              from: date
                ? `${date}T00:00:00Z`
                : '2022-10-15T00:00:00Z',
              to: date
                ? `${date}T23:59:59Z`
                : '2022-11-25T23:59:59Z'
            },
            maxCloudCoverage: Number(cloudCoverage) || 70
          },
          type: 'sentinel-2-l2a'
        }
      ]
    },
    output: {
      width: 512,
      height: 343.697,
      responses: [
        {
          identifier: 'default',
          format: {
            type: 'image/png'
          }
        }
      ]
    },
    // eslint-disable-next-line spaced-comment
    evalscript
  })
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  }
  return (
    fetch(`${process.env.REACT_APP_API_SENTINEL_HUB}/api/v1/process`, requestOptions)
      .then((response) => {
        const reader = response.body.getReader()
        return new ReadableStream({
          start (controller) {
            return pump()
            function pump () {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close()
                  return
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value)
                return pump()
              })
            }
          }
        })
      })
      // Create a new response out of the stream
      .then((stream) => new Response(stream))
      // Create an object URL for the response
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then(function (url) {
        return url
      })
      .catch((error) => console.log('error', error))
  )
}

export default getIndex
