import React, { useEffect, useRef } from 'react'
import './SelectCrop.scss'
import { array, func, bool } from 'prop-types'
import useServices from '../../../Hooks/useServices'

const SelectCrop = ({
  userLAndCrops,
  crops,
  setUserLand,
  setSpinner,
  setInterShow,
  useInterShow,
  setInterpretation,
  farmContex,
  inputDisable
}) => {
  const selectRef = useRef('')
  const { getAnalysisListApi, getInterpretationApi } = useServices()
  useEffect(() => {
    if (useInterShow === false) {
      selectRef.current.value = 1
    }
  }, [useInterShow])
  const handleCrops = async (e) => {
    if (e.target.value !== '1') {
      const landsCrops = userLAndCrops.filter(
        (land) => land.field_crop === e.target.value
      )
      const cropID = landsCrops.map((land) => land.field_crop_1)
      const reqAnalysisListApi = await getAnalysisListApi(farmContex[0].nid)
      const getNidInter = reqAnalysisListApi.filter(
        (element) => element.field_crop === cropID[0]
      )
      const RawInterpretation = await getInterpretationApi(getNidInter[0].nid)
      setInterpretation(RawInterpretation)
      setUserLand(
        userLAndCrops &&
          userLAndCrops.filter((land) => land.field_crop === e.target.value)
      )
      setInterShow(true)
    } else {
      setUserLand(userLAndCrops && userLAndCrops)
      setInterShow(false)
      setInterpretation('')
    }
  }
  return (
    <div className="select__farm">
      <h2 className="select__farm--title select__farm--titleCrop">
        Tipos de Cultivo
      </h2>
      <div>
        <select
          disabled={inputDisable}
          onChange={(e) => handleCrops(e)}
          className="select__farm--select"
          ref={selectRef}
          defaultValue={1}
        >
          <option value={1}>Todos los cultivos</option>
          {crops &&
            crops.map((e, i) => (
              <option key={i} value={e}>
                {e}
              </option>
            ))}
        </select>
      </div>
    </div>
  )
}

SelectCrop.propTypes = {
  userLAnd: array,
  setLand: array,
  setUserLand: func,
  crops: array,
  userLAndCrops: array,
  setSpinner: func,
  setInterShow: func,
  useInterShow: bool,
  setInterpretation: func,
  farmContex: array,
  inputDisable: bool
}

export default SelectCrop
