const getBounds = (rawData) => {
  const coords = { north: 0, south: 0, east: 0, west: 0 }
  const lat = []
  const long = []
  rawData.forEach((element) => {
    lat.push(element[0])
    long.push(element[1])
  })
  coords.north = Math.max(...long)
  coords.south = Math.min(...long)
  coords.east = Math.max(...lat)
  coords.west = Math.min(...lat)

  return coords
}

export default getBounds
