import React from 'react'
import { string } from 'prop-types'
import './Spinner.scss'

const Spinner = ({ text }) => {
  return (
    <div className="spinner">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
      <p>{text}</p>
    </div>
  )
}

Spinner.propTypes = {
  text: string
}
export default Spinner
