import React, { useEffect, useState } from 'react'
import './SelectDate.scss'
import { func, object, oneOfType, string, bool } from 'prop-types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import useServices from '../../../Hooks/useServices'
import useGetIndex from '../../../Hooks/useGetIndex'

const SelectDate = ({ setDateSentinel, dateSentinel, inputDisable }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [sentinelHubDates, setSentinelHubDates] = useState(new Date())
  const [token, setToken] = useState(null)
  const { sentinelDates } = useServices()
  const { tokenSentinel } = useGetIndex()

  useEffect(() => {
    const fecha = new Date()
    setDateSentinel(fecha.toJSON().slice(0, 10))
    const getDate = async () => {
      const rawToken = await tokenSentinel()
      const getDateSentinel = await sentinelDates(
        rawToken.res.data.access_token,
        startDate.toJSON().slice(0, 7)
      )
      setToken(rawToken.res.data.access_token)
      setSentinelHubDates(
        getDateSentinel.res.features.map((date) => {
          const dateS = new Date(date)
          dateS.setDate(dateS.getDate() + 1)
          return dateS.toJSON().slice(0, 10)
        })
      )
    }
    getDate()
  }, [])
  const handleMonthChange = async (date) => {
    const getDateSentinel = await sentinelDates(
      token,
      date.toJSON().slice(0, 7)
    )
    setSentinelHubDates(getDateSentinel.res.features.map((date) => {
      const dateS = new Date(date)
      dateS.setDate(dateS.getDate() + 1)
      return dateS.toJSON().slice(0, 10)
    }))
  }

  const handleSelectDate = (date) => {
    setStartDate(date)
    setDateSentinel(date.toJSON().slice(0, 10))
  }
  return (
    <div className="select__farm select__date">
      <h2 className="select__farm--title">Fecha</h2>
      <div className="select__date--container">
        <DatePicker
          disabled={inputDisable}
          dateFormat={'MMM d, yyyy'}
          selected={startDate}
          onChange={(date) => handleSelectDate(date)}
          onMonthChange={(date) => handleMonthChange(date)}
          dayClassName={(date) => {
            return (
              sentinelHubDates &&
              sentinelHubDates.map((d) =>
                date.getDate() === new Date(d).getDate() &&
                date.getMonth() === new Date(d).getMonth()
                  ? 'random'
                  : undefined
              )
            )
          }}
        />
      </div>
    </div>
  )
}

SelectDate.propTypes = {
  setDateSentinel: func,
  dateSentinel: oneOfType([object, string]),
  inputDisable: bool
}

export default SelectDate
