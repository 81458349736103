const sentinelIndex = (script) => {
  switch (script) {
    case 'RGB':
      return "//VERSION=3\nfunction setup() {\n    return {\n        input: ['B02', 'B03', 'B04', 'dataMask'],\n\n     output: {\n       id: 'default',\n      bands: 4,\n     }\n   }\n}\n\nfunction evaluatePixel(sample) {\n   return [2.5 * sample.B04, 2.5 * sample.B03, 2.5 * sample.B02, sample.dataMask]\n\n}\n"
    case 'EVI':
      return "//VERSION=3\nfunction setup() {\n    return {\n        input: ['B08', 'B04', 'B02', 'dataMask'],\n\n     output: {\n       id: 'default',\n      bands: 5,\n     }\n   }\n}\n\nfunction evaluatePixel(sample) {\n   let index = 2.5 * (sample.B08 - sample.B04) / (sample.B08 + 6 * sample.B04 - 7.5 * sample.B02 + 1);\n   \n   if (index<-0.5) return [0.41,0.41,0.41, sample.dataMask]\n      else if (index<0) return [0.50,0.50,0.50, sample.dataMask]\n      else if (index<0.1) return [0.64,0,0.14, sample.dataMask]\n      else if (index<0.2) return [0.84,0.18,0.15, sample.dataMask]\n      else if (index<0.3) return [0.95,0.42,0.26, sample.dataMask]\n      else if (index<0.4) return [0.99,0.68,0.38, sample.dataMask]\n      else if (index<0.5) return [0.99,0.87,0.54, sample.dataMask]\n      else if (index<0.6) return [0.85,0.93,0.54, sample.dataMask]\n      else if (index<0.7) return [0.65,0.85,0.41, sample.dataMask]\n      else if (index<0.8) return [0.40,0.74,0.38, sample.dataMask]\n      else if (index<0.9) return [0.10,0.59,0.31, sample.dataMask]\n      else if (index<=1) return [0,0.40,0.21, sample.dataMask]\n      else return [1, 1, 1, sample.dataMask]\n\n}\n"
    case 'SAVI' :
      return "//VERSION=3\nfunction setup() {\n    return {\n        input: ['B08', 'B04', 'dataMask'],\n\n     output: {\n       id: 'default',\n       bands: 4,\n     }\n   }\n}\n\nfunction evaluatePixel(sample) {\n   let index =  1.428 * ((sample.B08 - sample.B04) / (sample.B08 + sample.B04 + 0.428));\n   \n   if (index<-0.5) return [0.41,0.41,0.41, sample.dataMask]\n      else if (index<0) return [0.50,0.50,0.50, sample.dataMask]\n      else if (index<0.1) return [0.64,0,0.14, sample.dataMask]\n      else if (index<0.2) return [0.84,0.18,0.15, sample.dataMask]\n      else if (index<0.3) return [0.95,0.42,0.26, sample.dataMask]\n      else if (index<0.4) return [0.99,0.68,0.38, sample.dataMask]\n      else if (index<0.5) return [0.99,0.87,0.54, sample.dataMask]\n      else if (index<0.6) return [0.85,0.93,0.54, sample.dataMask]\n      else if (index<0.7) return [0.65,0.85,0.41, sample.dataMask]\n      else if (index<0.8) return [0.40,0.74,0.38, sample.dataMask]\n      else if (index<0.9) return [0.10,0.59,0.31, sample.dataMask]\n      else if (index<=1) return [0,0.40,0.21, sample.dataMask]\n      else return [1, 1, 1, sample.dataMask]\n\n}\n"
    case 'NDVI' :
      return "//VERSION=3\nfunction setup() {\n    return {\n        input: ['B08', 'B04', 'dataMask'],\n\n     output: {\n       id: 'default',\n       bands: 4,\n     }\n   }\n}\n\nfunction evaluatePixel(sample) {\n   let ndvi = (sample.B08 - sample.B04) / (sample.B08 + sample.B04)\n   \n   if (ndvi<-0.5) return [0,0,0, sample.dataMask]\n\n    else if (ndvi<-0.2) return [0.05,0.05,0.05, sample.dataMask]\n     \n    else if (ndvi<0) return [0.65, 0, 0.15, sample.dataMask]\n\n    else if (ndvi<0.1) return [0.84, 0.19, 0.15 , sample.dataMask]\n\n    else if (ndvi<0.2) return [0.96, 0.43, 0.26, sample.dataMask]\n\n    else if (ndvi<0.3) return [0.99, 0.68, 0.38, sample.dataMask]\n\n    else if (ndvi<0.4) return [1.0, 0.88, 0.55, sample.dataMask]\n\n    else if (ndvi<0.5) return [1, 1, 0.75, sample.dataMask]\n\n    else if (ndvi<0.6) return [0.85, 0.94, 0.55, sample.dataMask]\n\n    else if (ndvi<0.7) return [0.65, 0.85, 0.42, sample.dataMask]\n\n    else if (ndvi<0.8) return [0.40, 0.74, 0.39, sample.dataMask]\n\n    else if (ndvi<0.9) return [0.10, 0.60, 0.31, sample.dataMask]\n\n    else return [0, 0.41, 55, sample.dataMask]\n\n}\n"
    case 'GNDVI' :
      return "//VERSION=3\nfunction setup() {\n    return {\n        input: ['B08', 'B03', 'dataMask'],\n\n     output: {\n       id: 'default',\n       bands: 4,\n     }\n   }\n}\n\nfunction evaluatePixel(sample) {\n   let index = (sample.B08 - sample.B03) / (sample.B08 + sample.B03)\n   \n   if (index<-0.5) return [0.41,0.41,0.41, sample.dataMask]\n      else if (index<0) return [0.50,0.50,0.50, sample.dataMask]\n      else if (index<0.1) return [0.64,0,0.14, sample.dataMask]\n      else if (index<0.2) return [0.84,0.18,0.15, sample.dataMask]\n      else if (index<0.3) return [0.95,0.42,0.26, sample.dataMask]\n      else if (index<0.4) return [0.99,0.68,0.38, sample.dataMask]\n      else if (index<0.5) return [0.99,0.87,0.54, sample.dataMask]\n      else if (index<0.6) return [0.85,0.93,0.54, sample.dataMask]\n      else if (index<0.7) return [0.65,0.85,0.41, sample.dataMask]\n      else if (index<0.8) return [0.40,0.74,0.38, sample.dataMask]\n      else if (index<0.9) return [0.10,0.59,0.31, sample.dataMask]\n      else if (index<=1) return [0,0.40,0.21, sample.dataMask]\n      else return [1, 1, 1, sample.dataMask]\n\n}\n"
    case 'NDWI' :
      return "//VERSION=3\nfunction setup() {\n    return {\n        input: ['B08', 'B11', 'dataMask'],\n\n     output: {\n       id: 'default',\n       bands: 4,\n     }\n   }\n}\n\nfunction evaluatePixel(sample) {\n   let index = (sample.B08 - sample.B11) / (sample.B08 + sample.B11)\n   \n   if (index<-0.5) return [0.93,0.95,1, sample.dataMask]\n      else if (index<0) return [0.93,0.95,1, sample.dataMask]\n      else if (index<0.1) return [0.74,0.84,0.90, sample.dataMask]\n      else if (index<0.2) return [0.74,0.84,0.90, sample.dataMask]\n      else if (index<0.3) return [0.41,0.68,0.83, sample.dataMask]\n      else if (index<0.4) return [0.41,0.68,0.83, sample.dataMask]\n      else if (index<0.5) return [0.12,0.44,0.70, sample.dataMask]\n      else if (index<0.6) return [0.12,0.44,0.70, sample.dataMask]\n      else if (index<0.7) return [0.12,0.44,0.70, sample.dataMask]\n      else if (index<0.8) return [0.12,0.44,0.708, sample.dataMask]\n      else if (index<0.9) return [0.12,0.44,0.70, sample.dataMask]\n      else if (index<=1) return [0.12,0.44,0.70, sample.dataMask]\n      else return [1, 1, 1, sample.dataMask]\n\n}\n"
    case 'CI_RedEdge' :
      return "//VERSION=3\nfunction setup() {\n    return {\n        input: ['B07', 'B05', 'dataMask'],\n\n     output: {\n       id: 'default',\n       bands: 4,\n     }\n   }\n}\n\nfunction evaluatePixel(sample) {\n   let index = (sample.B07 / sample.B05) - 1;\n   \n   if (index<-0.5) return [0.41,0.41,0.41, sample.dataMask]\n      else if (index<0) return [0.50,0.50,0.50, sample.dataMask]\n      else if (index<1) return [0.64,0,0.14, sample.dataMask]\n      else if (index<1.5) return [0.84,0.18,0.15, sample.dataMask]      \n      else if (index<2) return [0.95,0.42,0.26, sample.dataMask]\n      else if (index<2.5) return [0.93,0.68,0.38, sample.dataMask]\n      else if (index<3) return [0.99,0.87,0.54, sample.dataMask]\n      else if (index<3.5) return [1,1,0.74, sample.dataMask]\n      else if (index<4) return [0.85,0.93,0.54, sample.dataMask]\n      else if (index<4.5) return [0.65,0.85,0.41, sample.dataMask]\n      else if (index<5) return [0.40,0.74,0.38, sample.dataMask]\n      else if (index<5.5) return [0.10,0.59,0.31, sample.dataMask]\n      else if (index<6) return [0,0.40,0.21, sample.dataMask]\n      else if (index<6.5) return [0,0.40,0.21, sample.dataMask]\n      else if (index<7) return [0,0.40,0.21, sample.dataMask]\n      else return [1, 1, 1, sample.dataMask]\n\n}\n"
    case 'NDRE' :
      return "//VERSION=3\nfunction setup() {\n    return {\n        input: ['B08', 'B05', 'dataMask'],\n\n     output: {\n       id: 'default',\n       bands: 4,\n     }\n   }\n}\n\nfunction evaluatePixel(sample) {\n   let ndre = (sample.B08 - sample.B05) / (sample.B08 + sample.B05)\n   \n   if (ndre<-0.5) return [0,0,0, sample.dataMask]\n\n    else if (ndre<-0.2) return [0.05,0.05,0.05, sample.dataMask]\n     \n    else if (ndre<0) return [0.65, 0, 0.15, sample.dataMask]\n\n    else if (ndre<0.1) return [0.84, 0.19, 0.15 , sample.dataMask]\n\n    else if (ndre<0.2) return [0.96, 0.43, 0.26, sample.dataMask]\n\n    else if (ndre<0.3) return [0.99, 0.68, 0.38, sample.dataMask]\n\n    else if (ndre<0.4) return [1.0, 0.88, 0.55, sample.dataMask]\n\n    else if (ndre<0.5) return [1, 1, 0.75, sample.dataMask]\n\n    else if (ndre<0.6) return [0.85, 0.94, 0.55, sample.dataMask]\n\n    else if (ndre<0.7) return [0.65, 0.85, 0.42, sample.dataMask]\n\n    else if (ndre<0.8) return [0.40, 0.74, 0.39, sample.dataMask]\n\n    else if (ndre<0.9) return [0.10, 0.60, 0.31, sample.dataMask]\n\n    else return [0, 0.41, 55, sample.dataMask]\n\n}\n"
    default:
      return "//VERSION=3\nfunction setup() {\n    return {\n        input: ['B08', 'B04', 'dataMask'],\n\n     output: {\n       id: 'default',\n       bands: 4,\n     }\n   }\n}\n\nfunction evaluatePixel(sample) {\n   let ndvi = (sample.B08 - sample.B04) / (sample.B08 + sample.B04)\n   \n   if (ndvi<-0.5) return [0,0,0, sample.dataMask]\n\n    else if (ndvi<-0.2) return [0.05,0.05,0.05, sample.dataMask]\n     \n    else if (ndvi<0) return [0.65, 0, 0.15, sample.dataMask]\n\n    else if (ndvi<0.1) return [0.84, 0.19, 0.15 , sample.dataMask]\n\n    else if (ndvi<0.2) return [0.96, 0.43, 0.26, sample.dataMask]\n\n    else if (ndvi<0.3) return [0.99, 0.68, 0.38, sample.dataMask]\n\n    else if (ndvi<0.4) return [1.0, 0.88, 0.55, sample.dataMask]\n\n    else if (ndvi<0.5) return [1, 1, 0.75, sample.dataMask]\n\n    else if (ndvi<0.6) return [0.85, 0.94, 0.55, sample.dataMask]\n\n    else if (ndvi<0.7) return [0.65, 0.85, 0.42, sample.dataMask]\n\n    else if (ndvi<0.8) return [0.40, 0.74, 0.39, sample.dataMask]\n\n    else if (ndvi<0.9) return [0.10, 0.60, 0.31, sample.dataMask]\n\n    else return [0, 0.41, 55, sample.dataMask]\n\n}\n"
  }
}

export default sentinelIndex
