import React from 'react'
import logo from '../../../img/intro.gif'
import { Link } from 'react-router-dom'
import './LogoAnimated.scss'

const LogoAnimated = () => {
  return (
    <div className='logo'>
      <Link to={'/'}>
        <img src={logo} alt='Nitrosoil4.0'/>
      </Link>
    </div>
  )
}

export default LogoAnimated
