const getSentinelDate = async (token, date) => {
  try {
    const body = {
      collections: ['sentinel-2-l2a'],
      datetime: `${date}-01T00:00:00Z/${date}-28T23:59:59Z`,
      intersects: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.22898962928667, 4.28973089969141],
            [-73.23966958647733, 4.289347717939935],
            [-73.24899155963804, 4.297314031040243],
            [-73.24988931838041, 4.298782095838902],
            [-73.248876582803, 4.299683817897049],
            [-73.24803003029136, 4.3028427775914],
            [-73.2480834912271, 4.3053820263779174],
            [-73.23391788333876, 4.306156406731132],
            [-73.23060852248133, 4.299832991455005],
            [-73.22257948004957, 4.291924362029263],
            [-73.21971463507597, 4.290341215060824],
            [-73.22898962928667, 4.28973089969141]
          ]
        ]
      },
      distinct: 'date',
      limit: 10
    }
    const req = await fetch(
      `${process.env.REACT_APP_API_SENTINEL_HUB}/api/v1/catalog/1.0.0/search`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      }
    )
    if (req.status === 200) {
      const res = await req.json()
      return {
        res
      }
    } else {
      console.log('Algo ha salido mal')
    }
  } catch (error) {
    console.log(error)
  }
}

export default getSentinelDate
