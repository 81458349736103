const sentinelIndexStatistics = (script) => {
  switch (script) {
    case 'RGB':
      return "//VERSION=3\nfunction setup() {\n    return {\n        input: ['B02', 'B03', 'B04', 'dataMask'],\n\n     output: {\n       id: 'default',\n      bands: 4,\n     }\n   }\n}\n\nfunction evaluatePixel(sample) {\n   return [2.5 * sample.B04, 2.5 * sample.B03, 2.5 * sample.B02, sample.dataMask]\n\n}\n"
    case 'EVI':
      return '//VERSION=3\nfunction setup() {\n  return {\n    input: [{\n      bands: [\n        "B08",\n        "B04",\n        "B02",\n        "SCL",\n        "dataMask"\n      ]\n    }],\n    output: [\n      {\n        id: "data",\n        bands: 3\n      },\n      {\n        id: "scl",\n        sampleType: "INT8",\n        bands: 1\n      },\n      {\n        id: "dataMask",\n        bands: 1\n      }]\n  };\n}\n\nfunction evaluatePixel(samples) {\n    let index = 2.5 * (samples.B08 - samples.B04) / (samples.B08 + 6 * samples.B04 - 7.5 * samples.B02 + 1);\n    return {\n        data: [index, samples.B08, samples.B04, samples.B02],\n        dataMask: [samples.dataMask],\n        scl: [samples.SCL]\n    };\n}\n'
    case 'SAVI' :
      return '//VERSION=3\nfunction setup() {\n  return {\n    input: [{\n      bands: [\n        "B08",\n        "B04",\n        "SCL",\n        "dataMask"\n      ]\n    }],\n    output: [\n      {\n        id: "data",\n        bands: 2\n      },\n      {\n        id: "scl",\n        sampleType: "INT8",\n        bands: 1\n      },\n      {\n        id: "dataMask",\n        bands: 1\n      }]\n  };\n}\n\nfunction evaluatePixel(samples) {\n    let index =  1.428 * ((samples.B08 - samples.B04) / (samples.B08 + samples.B04 + 0.428));\n    return {\n        data: [index, samples.B08, samples.B04],\n        dataMask: [samples.dataMask],\n        scl: [samples.SCL]\n    };\n}\n'
    case 'NDVI' :
      return '//VERSION=3\nfunction setup() {\n  return {\n    input: [{\n      bands: [\n        "B04",\n        "B08",\n        "SCL",\n        "dataMask"\n      ]\n    }],\n    output: [\n      {\n        id: "data",\n        bands: 2\n      },\n      {\n        id: "scl",\n        sampleType: "INT8",\n        bands: 1\n      },\n      {\n        id: "dataMask",\n        bands: 1\n      }]\n  };\n}\n\nfunction evaluatePixel(samples) {\n    let index = (samples.B08 - samples.B04) / (samples.B08 + samples.B04);\n    return {\n        data: [index, samples.B08, samples.B04],\n        dataMask: [samples.dataMask],\n        scl: [samples.SCL]\n    };\n}\n'
    case 'GNDVI' :
      return '//VERSION=3\nfunction setup() {\n  return {\n    input: [{\n      bands: [\n        "B08",\n        "B03",\n        "SCL",\n        "dataMask"\n      ]\n    }],\n    output: [\n      {\n        id: "data",\n        bands: 2\n      },\n      {\n        id: "scl",\n        sampleType: "INT8",\n        bands: 1\n      },\n      {\n        id: "dataMask",\n        bands: 1\n      }]\n  };\n}\n\nfunction evaluatePixel(samples) {\n    let index = (samples.B08 - samples.B03) / (samples.B08 + samples.B03);\n    return {\n        data: [index, samples.B08, samples.B03],\n        dataMask: [samples.dataMask],\n        scl: [samples.SCL]\n    };\n}\n'
    case 'NDWI' :
      return '//VERSION=3\nfunction setup() {\n  return {\n    input: [{\n      bands: [\n        "B08",\n        "B11",\n        "SCL",\n        "dataMask"\n      ]\n    }],\n    output: [\n      {\n        id: "data",\n        bands: 2\n      },\n      {\n        id: "scl",\n        sampleType: "INT8",\n        bands: 1\n      },\n      {\n        id: "dataMask",\n        bands: 1\n      }]\n  };\n}\n\nfunction evaluatePixel(samples) {\n    let index = (samples.B08 - samples.B11) / (samples.B08 + samples.B11 );\n    return {\n        data: [index, samples.B11, samples.B08],\n        dataMask: [samples.dataMask],\n        scl: [samples.SCL]\n    };\n}\n'
    case 'CI_RedEdge' :
      return '//VERSION=3\nfunction setup() {\n  return {\n    input: [{\n      bands: [\n        "B07",\n        "B05",\n        "SCL",\n        "dataMask"\n      ]\n    }],\n    output: [\n      {\n        id: "data",\n        bands: 2\n      },\n      {\n        id: "scl",\n        sampleType: "INT8",\n        bands: 1\n      },\n      {\n        id: "dataMask",\n        bands: 1\n      }]\n  };\n}\n\nfunction evaluatePixel(samples) {\n    let index = (samples.B07 / samples.B05) - 1;\n    return {\n        data: [index, samples.B07, samples.B05],\n        dataMask: [samples.dataMask],\n        scl: [samples.SCL]\n    };\n}\n'
    case 'NDRE' :
      return '//VERSION=3\nfunction setup() {\n  return {\n    input: [{\n      bands: [\n        "B08",\n        "B05",\n        "SCL",\n        "dataMask"\n      ]\n    }],\n    output: [\n      {\n        id: "data",\n        bands: 2\n      },\n      {\n        id: "scl",\n        sampleType: "INT8",\n        bands: 1\n      },\n      {\n        id: "dataMask",\n        bands: 1\n      }]\n  };\n}\n\nfunction evaluatePixel(samples) {\n    let index = (samples.B08 - samples.B05) / (samples.B08 + samples.B05);\n    return {\n        data: [index, samples.B08, samples.B05],\n        dataMask: [samples.dataMask],\n        scl: [samples.SCL]\n    };\n}\n'
    default:
      return '//VERSION=3\nfunction setup() {\n  return {\n    input: [{\n      bands: [\n        "B04",\n        "B08",\n        "SCL",\n        "dataMask"\n      ]\n    }],\n    output: [\n      {\n        id: "data",\n        bands: 2\n      },\n      {\n        id: "scl",\n        sampleType: "INT8",\n        bands: 1\n      },\n      {\n        id: "dataMask",\n        bands: 1\n      }]\n  };\n}\n\nfunction evaluatePixel(samples) {\n    let index = (samples.B08 - samples.B04) / (samples.B08 + samples.B04);\n    return {\n        data: [index, samples.B08, samples.B04],\n        dataMask: [samples.dataMask],\n        scl: [samples.SCL]\n    };\n}\n'
  }
}

export default sentinelIndexStatistics
