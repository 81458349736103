import React, { useEffect, useState, useContext } from 'react'
import SelectFarm from '../../atoms/SelectFarm/SelectFarm'
import SelectLand from '../../atoms/SelectLand/SelectLand'
import SelectDate from '../../atoms/SelectDate/SelectDate'
import SelectIndex from '../../atoms/SelectIndex/SelectIndex'
import SelectCloud from '../../atoms/SelectCloud/SelectCloud'
import SelectNutrient from '../../atoms/SelectNutrient/SelectNutrient'
import SelectCrop from '../../atoms/SelectCrop/SelectCrop'
import ShowAnalysis from '../../atoms/ShowAnalysis/ShowAnalysis'

import { userFarmContex } from '../../../App'
import useGetIndex from '../../../Hooks/useGetIndex'
// import Button from '../../atoms/Button/Button'

import {
  func,
  array,
  bool,
  object,
  oneOfType,
  string,
  number
} from 'prop-types'
import './Features.scss'
import useLoggin from '../../../Hooks/useLoggin'

const Features = ({
  setFarm,
  userFarm,
  setUrlImagep,
  setLandContext,
  setImgNutrient,
  setImgNutrientCompare,
  cloudCoverage,
  setCloudCoverage,
  eyeValueSel1,
  eyeValueSel2,
  eyeValueSel4,
  setEyeValueSel1,
  setEyeValueSel2,
  setEyeValueSel4,
  setDateSentinel,
  dateSentinel,
  setGeoJsonLandAll,
  setSpinner,
  setVegIndex,
  setInterShow,
  eyeValueSel3,
  setEyeValueSel3,
  useInterShow,
  setInterpretation,
  setSelectedIndex,
  selectedIndex,
  setinputDisable,
  inputDisable
}) => {
  const { userLand } = useLoggin()
  const [userLAndNid, setUserLandNid] = useState(null)
  const [userLAnd, setUserLand] = useState(null)
  const [userLAndCrops, setUserLandCrops] = useState(null)
  const [landInputDisable, setLandInputDisable] = useState(false)
  const [sentinel, setSentinel] = useState(true)
  const [crops, setCrops] = useState(null)
  const { getIndexFunc, tokenSentinel } = useGetIndex()
  const farmContex = useContext(userFarmContex)
  useEffect(() => {
    const getLand = async () => {
      setSpinner({ hidden: false, text: 'Cargando fincas' })
      const land = await userLand(userLAndNid)
      if (land.length > 0) {
        setLandInputDisable(false)
      } else {
        setLandInputDisable(true)
      }
      setUserLand(land)
      setUserLandCrops(land)
      const cropsData = new Set(land.map((land) => land.field_crop))
      setCrops([...cropsData])
      setTimeout(() => {
        setSpinner({ hidden: true, text: 'Cargando imagen satelital' })
      }, 1000)
    }
    userLAndNid && getLand()
  }, [userLAndNid])

  useEffect(() => {
    if (selectedIndex && farmContex[0] && cloudCoverage && dateSentinel) {
      const arraySentinel = [JSON.parse(farmContex[0].field_array_sentinel)]
      setSpinner({ hidden: false, text: 'Cargando imagen satelital' })
      const geturlimg = async () => {
        const rawToken = await tokenSentinel()
        const token = rawToken.res.data.access_token
        const evalScript = selectedIndex[0].name
        const urlImage = await getIndexFunc(
          arraySentinel[0],
          cloudCoverage,
          dateSentinel,
          token,
          evalScript
        )
        setSpinner({ hidden: true, text: 'Cargando imagen satelital' })
        setUrlImagep(urlImage)
      }
      geturlimg()
    }
  }, [selectedIndex, cloudCoverage, dateSentinel, selectedIndex])

  useEffect(() => {
    !farmContex[0] ? setinputDisable(true) : setinputDisable(false)
    !farmContex[0] ? setLandInputDisable(true) : setLandInputDisable(false)
    if (farmContex[0]) {
      farmContex[0].field_array_sentinel.length <= 0 ? setSentinel(false) : setSentinel(true)
    }
  }, [farmContex[0]])

  return (
    <div className="features">
      <div className="features__group--container">
        {
          <SelectFarm
            inputDisable={inputDisable}
            setFarm={setFarm}
            setUserLandNid={setUserLandNid}
            userFarm={userFarm}
          />
        }
      </div>
      <div className="features__group--container">
        <SelectCrop
          inputDisable={inputDisable}
          farmContex={farmContex}
          setInterpretation={setInterpretation}
          setSpinner={setSpinner}
          userLAndCrops={userLAndCrops}
          userLAnd={userLAnd}
          setUserLand={setUserLand}
          crops={crops}
          setInterShow={setInterShow}
          useInterShow={useInterShow}
        />
        <ShowAnalysis
          inputDisable={inputDisable}
          setEyeValueSel4={setEyeValueSel4}
          eyeValueSel4={eyeValueSel4}
        />
      </div>
      <div className="features__group--container">
        <SelectLand
          landInputDisable={landInputDisable}
          setSpinner={setSpinner}
          setGeoJsonLandAll={setGeoJsonLandAll}
          setCrops={setCrops}
          setLandContext={setLandContext}
          userLAnd={userLAnd}
          useInterShow={useInterShow}
        />
      </div>
      <div className="features__group--container">
        <div className="select__farm">
          <h2 className="select__farm--title select__farm--titleNutrient">
            Fertilidad suelo
          </h2>
        </div>
        <SelectNutrient
          inputDisable={inputDisable}
          setSpinner={setSpinner}
          setEyeValueSel1={setEyeValueSel1}
          eyeValueSel1={eyeValueSel1}
          setImgNutrient={setImgNutrient}
        />
        <SelectNutrient
          inputDisable={inputDisable}
          setSpinner={setSpinner}
          setEyeValueSel1={setEyeValueSel2}
          eyeValueSel1={eyeValueSel2}
          setImgNutrient={setImgNutrientCompare}
        />
      </div>
      {sentinel &&
        <>
          <div className="features__group--container">
            <SelectIndex
              inputDisable={inputDisable}
              setEyeValueSel3={setEyeValueSel3}
              eyeValueSel3={eyeValueSel3}
              setVegIndex={setVegIndex}
              setSelectedIndex={setSelectedIndex}
            />
          </div>
          <div className="features__group--container">
            <SelectDate
              inputDisable={inputDisable}
              dateSentinel={dateSentinel}
              setDateSentinel={setDateSentinel}
            />
          </div>
          <div className="features__group--container">
            <SelectCloud
              inputDisable={inputDisable}
              setCloudCoverage={setCloudCoverage}
            />
          </div>
        </>
      }
    </div>
  )
}

Features.propTypes = {
  userFarm: array,
  setUserFarm: func,
  setFarm: func,
  setUrlImagep: func,
  farm: func,
  visibleAna: bool,
  setVisibleAna: func,
  setLandContext: func,
  setImgNutrient: func,
  setCloudCoverage: func,
  cloudCoverage: number,
  eyeValueSel1: bool,
  eyeValueSel2: bool,
  eyeValueSel4: bool,
  setEyeValueSel1: func,
  setEyeValueSel2: func,
  setEyeValueSel3: func,
  setEyeValueSel4: func,
  setDateSentinel: func,
  dateSentinel: oneOfType([object, string]),
  setImgNutrientCompare: func,
  setGeoJsonLandAll: func,
  setSpinner: func,
  setVegIndex: func,
  setInterShow: func,
  eyeValueSel3: bool,
  useInterShow: bool,
  setInterpretation: func,
  setSelectedIndex: func,
  selectedIndex: array,
  setinputDisable: func,
  inputDisable: bool
}

export default Features
