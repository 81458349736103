const weatherVar = (arrayTemporal, arrayWeatherVar) => {
  const weatherWind = arrayTemporal.concat(
    arrayWeatherVar.map((e) => e.weather_wind.replace('m/s', '').trim())
  )
  const weatherRainProbability = arrayTemporal.concat(
    arrayWeatherVar.map((e) =>
      e.weather_rainProbability.replace('%', '').trim()
    )
  )
  const weatherHumidity = arrayTemporal.concat(
    arrayWeatherVar.map((e) => e.weather_humidity.replace('%', '').trim())
  )
  const weatherTemperature = arrayTemporal.concat(
    arrayWeatherVar.map((e) => e.weather_temperature.trim())
  )
  const weatherSoilMoistureIndex = arrayTemporal.concat(
    arrayWeatherVar.map((e) =>
      e.weather_soil_moisture_index.replace('idx', '').trim()
    )
  )
  const weatherSoilMoistureDeficit = arrayTemporal.concat(
    arrayWeatherVar.map((e) =>
      e.weather_soil_moisture_deficit.replace('mm', '').trim()
    )
  )
  const weatherGrowingDegreeDaysAccumulated = arrayTemporal.concat(
    arrayWeatherVar.map((e) =>
      e.weather_growing_degree_days_accumulated.replace('gdd', '').trim()
    )
  )
  const weatherEvapotranspiration = arrayTemporal.concat(
    arrayWeatherVar.map((e) =>
      e.weather_evapotranspiration.replace('mm', '').trim()
    )
  )
  const weatherDirectRad = arrayTemporal.concat(
    arrayWeatherVar.map((e) => e.weather_direct_rad.replace('W', '').trim())
  )

  return [
    { 0: weatherWind, name: 'Viento' },
    { 1: weatherRainProbability, name: 'Lluvia' },
    { 2: weatherHumidity, name: 'Humedad' },
    { 3: weatherTemperature, name: 'Temperatura' },
    { 4: weatherSoilMoistureIndex, name: 'SMI' },
    { 5: weatherSoilMoistureDeficit, name: 'SMD' },
    { 6: weatherEvapotranspiration, name: 'ET' },
    { 7: weatherDirectRad, name: 'Radiación directa' },
    { 8: weatherGrowingDegreeDaysAccumulated, name: 'GDD' }
  ]
}

export default weatherVar
