/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import useServices from '../../../Hooks/useServices'
ChartJS.register(ArcElement, Tooltip, Legend)

export function PieChar2 ({ lotes, finca }) {
  const { landsU } = useServices()
  const [cultivos, setCultivos] = useState(null)
  const [cantidadCultivos, setCantidadCultivos] = useState(null)

  useEffect(() => {
    if (finca[0]) {
      const getLotes = async () => {
        const lotes = await landsU(finca[0].nid)
        if (lotes) {
          const cultivos = lotes.map((lote) => lote.field_crop)
          // Obtener un arreglo sin elementos repetidos
          const arregloSinRepetir = [...new Set(cultivos)]
          // Obtener el número total de elementos en el arreglo sin repetir
          const totalElementos = cultivos.length
          // Obtener el conteo de cada elemento en el arreglo original
          const conteo = cultivos.reduce((acc, val) => {
            acc[val] = (acc[val] || 0) + 1
            return acc
          }, {})
          setCantidadCultivos(Object.values(conteo))
          // Calcular el porcentaje que representa cada elemento
          const arregloConPorcentaje = arregloSinRepetir.map((elem) => {
            const porcentaje = ((conteo[elem] / totalElementos) * 100).toFixed(
              2
            )
            return `${elem} ${parseInt(porcentaje)}%`
          })
          setCultivos(arregloConPorcentaje) // ["pera 40.00%", "manzana 20.00%", "banano 20.00%", "mora 20.00%"]
        }
      }
      getLotes()
    }
  }, [finca, lotes])
  const data = {
    labels: cultivos && cultivos,
    datasets: [
      {
        label: '# of Votes',
        data: cantidadCultivos && cantidadCultivos,
        backgroundColor: [
          '#6aa84f',
          '#a2c48f',
          '#8cc276',
          '#d0f0c0',
          '#e8f5dc',
          '#d9ead3'
        ],
        borderColor: [
          '#e8f5dc',
          '#d9ead3',
          '#7bb55d',
          '#c3a3ff',
          '#85b18c',
          '#64a041'
        ],
        borderWidth: 1
      }
    ]
  }
  const options = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: '#000',
          font: {
            size: 20
          }
        }
      }
    }
  }
  return (
    <Pie
      options={options}
      onLoad={() => alert('hola')}
      style={{ width: '300px', height: '300px' }}
      data={data}
    />
  )
}
