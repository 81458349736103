import swal from 'sweetalert'

const getAnalysis = async (uid) => {
  const req = await fetch(`${process.env.REACT_APP_API}/read/${uid}/farm/analysis`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (req.status === 200) {
    const res = await req.json()
    return res
  } else {
    swal('Algo ha salido mal', 'Verifica tus credenciales', 'error')
  }
}

export default getAnalysis
