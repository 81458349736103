import React from 'react'
import WeatherInfo from '../../atoms/WeatherInfo/WeatherInfo'
import './HeaderCenter.scss'

const HeaderCenter = () => {
  return (
    <div className='header__center'>
      <WeatherInfo />
    </div>
  )
}

export default HeaderCenter
