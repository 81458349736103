import React, { useEffect, useContext, useState } from 'react'
import Spinner from '../Spinner/Spinner'
import { userFarmContex } from '../../../App'
import useServices from '../../../Hooks/useServices'
import temperatureImage from '../../../img/weather/temperature.svg'
import humidityImage from '../../../img/weather/humidity.svg'
import windImage from '../../../img/weather/wind.svg'
import rainImage from '../../../img/weather/rain.svg'
import './WeatherInfo.scss'

const WeatherInfo = () => {
  const farmContex = useContext(userFarmContex)
  const { weather } = useServices()
  const [dataWeather, setDataWeather] = useState('')
  const [url, setUrl] = useState('')
  const [spinner, setSpinner] = useState(false)

  useEffect(() => {
    setSpinner(true)
    const weatherFarm = async (fId) => {
      const data = await weather(fId)
      setDataWeather(data.general_data)
      setTimeout(() => {
        setSpinner(false)
      }, 1500)
    }
    setTimeout(() => {
      setSpinner(false)
    }, 6000)
    farmContex.length > 0 && weatherFarm(farmContex[0].nid)
  }, [farmContex])

  useEffect(() => {
    if (dataWeather) {
      const uri = `https://admin.nitrosoil4.com/sites/default/files/2022-12/w-${dataWeather.weather_ico}.png`
      setUrl(uri)
    }
  }, [dataWeather])
  return (
    <>
    {farmContex[0] &&
    <div className='weather'>
      {spinner && <Spinner text='Obteniendo datos del clima'/>}
      <div className='weather--container'>
        <div className='weather--c__top'>
          <div className='weather_condition--icon'>
           <img className='weather--image' src={url} alt="Condición" />
          </div>
          <div className='weather_condition'>
            <span>Condición:</span>
            { dataWeather.weather_condition && <span className='weather-info'>{dataWeather.weather_condition}</span> }
          </div>
        </div>
        <div className='weather--c__bottom'>
          <div className='weather--temperature'>
            <img className='weather--image' src={temperatureImage} alt="Temperatura" />
            { dataWeather.weather_temperature && <span className='weather-info'>{dataWeather.weather_temperature}</span> }
            <span>Temperatura</span>
          </div>
          <div className='weather--humidity'>
            <img className='weather--image' src={humidityImage} alt="Humedad" />
            { dataWeather.weather_humidity && <span className='weather-info'>{dataWeather.weather_humidity}</span> }
            <span>Humedad</span>
          </div>
          <div className='weather--wind'>
            <img className='weather--image' src={windImage} alt="Vientos" />
            { dataWeather.weather_wind && <span className='weather-info'>{dataWeather.weather_wind}</span> }
            <span>Vientos</span>
          </div>
          <div className='weather--rainProbability'>
            <img className='weather--image' src={rainImage} alt="Probabilidad" />
            { dataWeather.weather_rainProbability && <span className='weather-info'>{dataWeather.weather_rainProbability}</span> }
            <span>Probabilidad</span>
          </div>
        </div>
      </div>
    </div>
    }
    </>
  )
}

export default WeatherInfo
