import React from 'react'
import mapActivate from '../../../img/mapActivate.png'
import reporte from '../../../img/reporte.png'
import Button from '../../atoms/button/Button'
import { bool } from 'prop-types'

import './NavBar.scss'

const NavBar = ({ reporteHidden }) => {
  //   const [activate, setActivate] = useState(null)

  return (
    <div className="navbar">
      <Button
        rute="home"
        className="btn-primary"
        textColor={'#243746'}
        text={'Mapa'}
        icon={mapActivate}
      />
      {reporteHidden &&
      <Button
        rute="reporte"
        className="btn-primary"
        bgColor={'transparent'}
        textColor={'white'}
        text={'Reporte'}
        icon={reporte}
      />
      }
    </div>
  )
}

NavBar.propTypes = {
  reporteHidden: bool
}
export default NavBar
