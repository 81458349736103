import React from 'react'
import './Page404.scss'
import logo from '../../../img/logo.png'
import { useNavigate } from 'react-router-dom'
const Page404 = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/home')
  }
  return (
    <div className="page404">
      <div className="page404__content">
        <picture className="page404__content--logo">
          <img
            className="page404__content--logo--image"
            src={logo}
            alt="logo nitrosoil4.0"
          />
        </picture>
        <h1 className="page404__content--title">Error 404</h1>
        <a className="button-green" onClick={() => handleClick()}>
          Volver
        </a>
      </div>
    </div>
  )
}

export default Page404
