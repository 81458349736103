import getIndex from '../services/getIndex'
import getToken from '../services/getToken'

const useGetIndex = (arrayC) => {
  // const [url, setUrl] = useState(null)
  const getIndexFunc = async (arrayC, cloudCoverage, date, token, script) => getIndex(arrayC, cloudCoverage, date, token, script)
  const tokenSentinel = async () => getToken()
  return {
    getIndexFunc,
    tokenSentinel
  }
}

export default useGetIndex
