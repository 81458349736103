import React from 'react'
import { Line } from 'react-chartjs-2'
import Spinner from '../Spinner/Spinner'
import './LineChart.scss'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineController,
  BarController
} from 'chart.js'
import { object, bool } from 'prop-types'
// import Spinner from '../Spinner/Spinner'

const LineChart = ({ info, loader, theVarWeather }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineController,
    BarController
  )
  // color de los textos del linechart
  ChartJS.defaults.color = '#fff'
  const options = {
    responsive: true,
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left'
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false
        }
      }
    },
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: false,
        text: 'NitroSoil4.0'
      }
    }
  }
  const data = {
    labels: info && info.labelsData,
    datasets: [
      {
        type: 'line',
        label: info ? info.dataIn : ' ',
        data: info && info.indice,
        borderColor: '#3DAE2B',
        backgroundColor: '#3DAE2B',
        yAxisID: 'y'
      },
      {
        type: 'line',
        label: theVarWeather ? theVarWeather.name : 'seleccione',
        data: theVarWeather ? theVarWeather.value : [null],
        borderColor: 'rgb(124, 181, 236)',
        backgroundColor: 'rgb(124, 181, 236)',
        yAxisID: 'y1',
        hidden: false
      }
    ]
  }

  const heightLine = '40vh'
  const widthLine = '100%'

  return (
    <div className="line__chart">
      {loader && <Spinner text={'Obteniendo datos'} />}
      <Line
        type="bar"
        options={options}
        data={data}
        height={heightLine}
        widthline={widthLine}
      />
    </div>
  )
}

LineChart.propTypes = {
  info: object,
  theVarWeather: object,
  loader: bool
}

export default LineChart
