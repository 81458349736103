import React from 'react'
import './StatisticsImage.scss'

const StatisticsImage = (urlImagep) => {
  return (
    <div className='statistics-image'>
      <img src={urlImagep.urlImagep} alt="" />
    </div>
  )
}

export default StatisticsImage
