import React from 'react'
import ButtonLogout from '../../atoms/ButtonLogout/ButtonLogout'
import InfoFarm from '../../atoms/InfoFarm/InfoFarm'
import { array } from 'prop-types'
import './HeaderRight.scss'

const HeaderRight = ({ farm }) => {
  return (
    <div className="header__right">
        <InfoFarm/>
        <ButtonLogout/>
    </div>
  )
}

HeaderRight.propTypes = {
  farm: array
}
export default HeaderRight
