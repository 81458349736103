import React from 'react'
import Button from '../../atoms/button/Button'
import { string, object } from 'prop-types'
import './DataItem.scss'
import alertIcon from '../../../img/alert-icon.svg'

const DataItem = ({ data }) => {
  return (
    <div className="data__item">
      { data.name && <p>{data.name}</p> }
      { data.value && <p>{data.value.toFixed(2)}</p>}
      { data.state === 'alto' && <Button bgColor={'#3DAE2B'} textColor={'white'} icon={alertIcon} text={data.state}/>}
      { data.state === 'normal' && <Button bgColor={'#ff6d6d'} textColor={'white'} icon={alertIcon} text={data.state}/>}
      { data.state === 'neutro' && <Button bgColor={'#F4B223'} textColor={'white'} icon={alertIcon} text={data.state}/>}
      { data.state === 'bajo' && <Button bgColor={'red'} textColor={'white'} icon={alertIcon} text={data.state}/>}
    </div>
  )
}

DataItem.propTypes = {
  value: string,
  data: object
}

export default DataItem
