import React from 'react'
import DataItem from '../../molecules/DataItem/DataItem'
import { array, func } from 'prop-types'
import './Interpretation.scss'

const Interpretation = ({ setInterShow, interpretation }) => {
  const handleClose = (e) => {
    setInterShow(false)
  }
  return (
    <div className={'interpretation'}>
      <div className='interpretation__close'>
        <span className='interpretation__close--icon' onClick={(e) => handleClose(e)}>X</span>
      </div>
      <div className='interpretation__content'>
        <p className='interpretation__content--title'>Interpretacion</p>
        <p className='interpretation__content--description'>Estos resultados corresponden al analisis de suelo</p>
      <div className='interpretation__items'>
      {interpretation &&
        interpretation.map((data, i) => (
          <DataItem key={i} data={data}/>
        ))}
      </div>
      </div>
    </div>
  )
}

Interpretation.propTypes = {
  interpretation: array,
  setInterShow: func
}

export default Interpretation
