
const indexDescripcion = {
  EVI: 'El método de índice de vegetación mejorado es un índice optimizado para la vegetación que considera la influencia atmosférica y de fondo. A diferencia del NDVI, es menos sensible al ruido de fondo y atmosférico y no se satura tanto al visualizar áreas con vegetación densa.',
  SAVI: 'El método de índice de vegetación ajustado al suelo minimiza la influencia del brillo del suelo mediante un factor de corrección. Se usa comúnmente en regiones áridas con baja cubierta vegetal y produce valores entre -1.0 y 1.0.',
  NDVI: 'El NDVI mide el verdor y densidad de la vegetación capturada en imágenes de satélite mediante la diferencia entre la reflectancia en las bandas rojo visible e infrarrojo cercano. El valor resultante varía de -1 a 1.',
  GNDVI: 'El índice de vegetación de diferencia normalizada verde se utiliza para estimar la actividad fotosintética y el consumo de agua y nitrógeno de la vegetación.',
  NDWI: 'El índice de agua de diferencia normalizada resalta las masas de agua en imágenes de satélite mediante la reducción de la reflectancia del suelo y la vegetación.',
  CI_RedEdge: 'El índice de clorofila del borde rojo utiliza una banda espectral estrecha para calcular la clorofila de la vegetación.',
  NDRE: 'El NDRE es un mejor indicador que el NDVI para cultivos en temporada media y tardía que han acumulado mucha clorofila, ya que la luz de borde rojo puede penetrar más profundamente que la luz roja.',
  RGB: 'El índice de vegetación RGB se utiliza para estimar la vegetación en áreas urbanas a partir de imágenes de satélite de alta resolución mediante la luz visible y cercana al infrarrojo cercano reflejada en las hojas de las plantas.'
}

export default indexDescripcion
