import swal from 'sweetalert'

const getAnalysisList = async (nid) => {
  const req = await fetch(`${process.env.REACT_APP_API}/list/${nid}/analysis`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (req.status === 200) {
    const res = await req.json()
    return res
  } else {
    swal('Algo ha salido mal', `/list/${nid}/analysis`, 'error')
  }
}

export default getAnalysisList
