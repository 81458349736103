const diasSemana = [
  'domingo',
  'lunes',
  'martes',
  'miércoles',
  'jueves',
  'viernes',
  'sábado'
]

const meses = [
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre'
]

function formatDate () {
  const fecha = new Date(Date.now())
  const diaSemana = diasSemana[fecha.getUTCDay()]
  const mes = meses[fecha.getUTCMonth()]
  const dia = fecha.getUTCDate()
  const anio = fecha.getUTCFullYear()

  return diaSemana + ', ' + dia + ' de ' + mes + ' de ' + anio
}

export default formatDate
