import sentinelIndexStatistics from '../helpers/sentinelIndexStatistics'
const getStatistics = async (arrayC, token, startDate, endDate, interval = 9, datain, cloudCoverage) => {
  const evals = sentinelIndexStatistics(datain)
  const body = {
    input: {
      bounds: {
        geometry: {
          type: 'Polygon',
          coordinates: [arrayC]
        }
      },
      data: [
        {
          dataFilter: {
            maxCloudCoverage: cloudCoverage
          },
          type: 'sentinel-2-l2a'
        }
      ]
    },
    aggregation: {
      timeRange: {
        from: `${startDate}T00:00:00Z`,
        to: `${endDate}T23:59:59Z`
      },
      aggregationInterval: {
        of: `P${interval}D`
      },
      width: 300,
      height: 300,
      evalscript: evals
    },
    calculations: {
      default: {}
    }
  }
  const req = await fetch(
    `${process.env.REACT_APP_API_SENTINEL_HUB}/api/v1/statistics`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization:
            `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
  )
  if (req.status === 200) {
    const res = await req.json()
    return {
      res
    }
  } else {
    console.log('no se pudo obtener las estadisticas..')
  }
}

export default getStatistics
