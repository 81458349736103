import React, { useEffect, useState } from 'react'
import './SelectIndex.scss'
import { func, bool } from 'prop-types'
import useServices from '../../../Hooks/useServices'
import HiddenShow from '../HiddenShow/HiddenShow'

const SelectIndex = ({
  setSelectedIndex,
  setVegIndex,
  setEyeValueSel3,
  eyeValueSel3,
  inputDisable
}) => {
  const { vegeIndex } = useServices()
  const [indexs, setIndexs] = useState(null)
  // defaultValue Selected
  useEffect(() => {
    const getIndex = async () => {
      const a = await vegeIndex()
      setIndexs(a)
      setVegIndex(a)
    }
    getIndex()
  }, [])
  const handleIndex = (e) => {
    e.target.value === '1'
      ? setSelectedIndex([])
      : setSelectedIndex(
        indexs.filter((index) => index.name === e.target.value)
      )
  }
  return (
    <div className="select__farm select__index">
      <h2 className="select__farm--title select__farm--titleSatelital">Imagen satelital</h2>
      <div className="select__eye">
        <HiddenShow
          setEyeValueSel1={setEyeValueSel3}
          eyeValueSel1={eyeValueSel3}
        />
        <select
          disabled={inputDisable}
          onChange={(e) => handleIndex(e)}
          className="select__farm--select"
        >
          <option value={1}>Seleccione</option>
          {indexs &&
            indexs.map((e) => (
              <option key={e.name} value={e.name}>
                {e.name}
              </option>
            ))}
        </select>
      </div>
    </div>
  )
}

SelectIndex.propTypes = {
  setSelectedIndex: func,
  setVegIndex: func,
  eyeValueSel3: bool,
  setEyeValueSel3: func,
  inputDisable: bool
}

export default SelectIndex
