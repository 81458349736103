import React, { useState } from 'react'
import './SelectCloud.scss'
import { func, bool } from 'prop-types'

const SelectCloud = ({ setCloudCoverage, inputDisable }) => {
  const [coverage, setCoverage] = useState('50%')
  const handleRange = (e) => {
    setCoverage(`${e.target.value}%`)
  }
  const handleReq = (e) => {
    setCloudCoverage(e.target.value)
  }
  return (
    <div className="select__farm select__cloud">
      <h2 className="select__farm--title">Nubosidad {coverage}</h2>
      <div className="select__cloud--container">
        <input
          disabled={inputDisable}
          className="select__cloud--input"
          onClick={handleReq}
          onChange={handleRange}
          type="range"
        />
      </div>
    </div>
  )
}

SelectCloud.propTypes = {
  setCloudCoverage: func,
  inputDisable: bool
}

export default SelectCloud
