import swal from 'sweetalert'

const getGoogleKey = async () => {
  const req = await fetch(`${process.env.REACT_APP_API}/googleapi/key`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (req.status === 200) {
    const { data: { google_api: googleApi } } = await req.json()
    return {
      googleApi
    }
  } else {
    swal('Algo ha salido mal', 'no se pudo obtener Google Key ', 'error')
  }
}

export default getGoogleKey
