import React, { useEffect, useRef } from 'react'
import './SelectLand.scss'
import { array, func, bool } from 'prop-types'

const SelectLand = ({
  setLandContext,
  userLAnd,
  setGeoJsonLandAll,
  setSpinner,
  useInterShow,
  landInputDisable
}) => {
  // defaultValue Selected
  const selectLand = useRef('')
  useEffect(() => {
    if (useInterShow === true) {
      selectLand.current.value = 'all'
      setGeoJsonLandAll(userLAnd)
    } else {
      selectLand.current.value = 1
      setLandContext('[]')
      setGeoJsonLandAll([])
    }
  }, [useInterShow])
  const handleLand = (e) => {
    setSpinner({ hidden: false, text: 'Obteniendo listado de lotes' })
    if (e.target.value === '1') {
      setLandContext('[]')
      setGeoJsonLandAll([])
    }
    e.target.value === 'all' && setGeoJsonLandAll(userLAnd)
    if (e.target.value !== '1' && e.target.value !== 'all') {
      setLandContext(userLAnd.filter((land) => land.nid === e.target.value))
    }
    setTimeout(() => {
      setSpinner({ hidden: true, text: 'Obteniendo listado de cultivos' })
    }, 1000)
  }

  return (
    <div className="select__farm">
      <h2 className="select__farm--title select__farm--titleLand">Lotes</h2>
      <div>
        <select
          disabled={landInputDisable}
          onChange={(e) => handleLand(e)}
          className="select__farm--select"
          ref={selectLand}
        >
          <option value={1}>Seleccione</option>
          <option value="all">Todos los lotes</option>
          {userLAnd &&
            userLAnd.map((e) => (
              <option key={e.nid} value={e.nid}>
                {e.field_name}
              </option>
            ))}
        </select>
      </div>
    </div>
  )
}

SelectLand.propTypes = {
  userLAnd: array,
  setLand: array,
  setLandContext: func,
  setCrops: func,
  setGeoJsonLandAll: func,
  setSpinner: func,
  useInterShow: bool,
  landInputDisable: bool
}

export default SelectLand
