import React from 'react'
import { useNavigate } from 'react-router-dom'
import LogoAnimated from '../../atoms/LogoAnimated/LogoAnimated'

import './Intro.scss'

const Intro = () => {
  const navigate = useNavigate()
  setTimeout(() => {
    navigate('/login')
  }, 3750)

  return (
    <div className="intro">
      <LogoAnimated />
    </div>
  )
}

export default Intro
