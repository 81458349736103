import React from 'react'
import { string } from 'prop-types'
import './Button.scss'
import { useNavigate } from 'react-router-dom'

const Button = ({ textColor, text, bgColor, icon, infoText, rute }) => {
  const navigate = useNavigate()
  return (
    <button
      onClick={() => navigate(`${rute}`) }
      className="btn__item"
      style={{ color: textColor, backgroundColor: bgColor }}
    >
      {icon && (
        <abbr
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          title={infoText}
        >
          <img className="btn__item--icon" src={icon} alt={`icon ${icon}`} />
        </abbr>
      )}
      {text}
    </button>
  )
}

Button.propTypes = {
  textColor: string,
  text: string,
  bgColor: string,
  icon: string,
  infoText: string,
  rute: string
}

export default Button
