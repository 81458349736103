// Dependencies
import React, { useEffect, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
// Components
import Home from './components/pages/Home/Home'
import Reporte from './components/pages/Reporte/Reporte'
import Header from './components/template/Header/Header'
import Intro from './components/pages/Intro/Intro'
import Login from './components/pages/Login/Login'
import Page404 from './components/pages/404/Page404'
// Styles
import './sass/base/base.scss'
import './App.scss'

export const userFarmContex = React.createContext()
export const userLandContex = React.createContext()

function App () {
  const [currentRoute, setCurrentRoute] = useState(null)
  const location = useLocation()
  const [farm, setFarm] = useState([])
  const [landContext, setLandContext] = useState([])
  const [reporte, setReporte] = useState({})
  const [reporteHidden, setReporteHidden] = useState(false)

  useEffect(() => {
    setCurrentRoute(location.pathname)
  }, [location])
  return (
    <userFarmContex.Provider value={farm}>
      <userLandContex.Provider value={landContext}>
        <div className="main__container">
          <header>
            {currentRoute !== '/login' ? <Header reporteHidden={reporteHidden} farm={farm} /> : null}
          </header>
          <main>
            <Routes>
              <Route path="/" element={<Intro />} />
              <Route
                path="/home"
                element={
                  <Home
                    setReporteHidden={setReporteHidden}
                    setReporte={setReporte}
                    reporte={reporte}
                    setLandContext={setLandContext}
                    farm={farm}
                    setFarm={setFarm}
                    setCurrentRoute={setCurrentRoute}
                  />
                }
              />
              <Route
                path="/reporte"
                element={<Reporte farm={farm} reporte={reporte} />}
              />
              <Route
                path="/login"
                element={<Login setCurrentRoute={setCurrentRoute} />}
              />
              <Route
                path="/*"
                element={<Page404/>}
              />
            </Routes>
          </main>
        </div>
      </userLandContex.Provider>
    </userFarmContex.Provider>
  )
}

export default App
