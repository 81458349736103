import React from 'react'
import { useNavigate } from 'react-router-dom'
import './ButtonLogout.scss'

const ButtonLogout = () => {
  const navigate = useNavigate()
  const handleLogout = () => {
    window.localStorage.clear()
    window.location.replace('./')
    navigate('/')
  }
  return (
    <div className="logout--container">
      <button className='logout--btn' onClick={() => handleLogout()}>Salir</button>
    </div>
  )
}

export default ButtonLogout
