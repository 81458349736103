import swal from 'sweetalert'

const getFarm = async (uid) => {
  const req = await fetch(`${process.env.REACT_APP_API}/list/${uid}/farms`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (req.status === 200) {
    const res = await req.json()
    window.localStorage.setItem('Farm', JSON.stringify(res))
    window.localStorage.setItem('uid', JSON.stringify(uid))
    return res
  } else {
    swal('Algo ha salido mal', 'Verifica tus credenciales', 'error')
  }
}

export default getFarm
