/* eslint-disable multiline-ternary */
/* eslint-disable camelcase */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { array, object } from 'prop-types'
import useServices from '../../../Hooks/useServices'
import formatCoordinates from '../../../helpers/formatCoord'
import logo from '../../../img/logoDark.png'
import logo2 from '../../../img/logo.png'
import temperatureImage from '../../../img/weather/temperature.png'
import humidityImage from '../../../img/weather/humidity.png'
import windImage from '../../../img/weather/wind.png'
import rainImage from '../../../img/weather/rain.png'
import ulrMap from '../../../helpers/urlMapStatic'
import formatDate from '../../../helpers/date'
import useBounds from '../../../Hooks/useBounds'
import Spinner from '../../atoms/Spinner/Spinner'
import './Reporte.scss'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image
} from '@react-pdf/renderer'
import useGetIndex from '../../../Hooks/useGetIndex'
import obtenerTamanoImagen from '../../../services/getImage'
import indexDescripcion from '../../../helpers/indexDescription'
import { useNavigate } from 'react-router-dom'

const Reporte = ({ farm, reporte }) => {
  // la finca seleccionada
  const base = 200
  const [finca] = useState(farm[0])
  const [lotes, setLotes] = useState(null)
  const [weatherF, setWeatherF] = useState(null)
  const [tiposCultivos, setTiposCultivos] = useState(null)
  const [newCenter, setNewCenter] = useState()
  const [stringLand, setStringLand] = useState(null)
  const [stringFarm, setStringFarm] = useState(null)
  const [interpre, setInterpre] = useState(null)
  const [idinter, setIdinter] = useState(null)
  const [spinner, setSpinner] = useState(true)
  const [sentinelUrlImage, setSentinelUrlImage] = useState(false)
  const [baseImageAlto, setBaseImageAlto] = useState(null)
  const [baseImageAncho, setBaseImageAncho] = useState(null)

  // useService para traer las funciones de los servicios
  const {
    landsU,
    getAnalysisListApi,
    getInterpretationApi,
    vegeIndex,
    weather
  } = useServices()
  const { tokenSentinel, getIndexFunc } = useGetIndex()
  const { farmBounds } = useBounds()
  const navigate = useNavigate()

  // eslint-disable-next-line space-before-function-paren
  function validarObjeto(objeto) {
    const keys = Object.keys(objeto)
    for (const key of keys) {
      if (
        !Object.prototype.hasOwnProperty.call(objeto, key) ||
        objeto[key] == null ||
        objeto[key] === ''
      ) {
        return false
      }
    }
    return true
  }
  useEffect(() => {
    console.log(finca)
    if (!validarObjeto(farm) || !validarObjeto(reporte)) {
      navigate('/home')
    }
    const construReporte = async () => {
      setSpinner(true)
      // obteniendo los datos del clima de la finca
      const weatherInfo = await weather(finca.nid)
      setWeatherF(weatherInfo)
      // obteniendo los lotes de la finca
      const lotes = await landsU(finca.nid)
      setLotes(lotes)
      // obteniendo los crops tipos de cultivo de los lotes
      const crops = new Set(lotes.map((lote) => lote.field_crop))
      setTiposCultivos([...crops])
      // obteniendo los id de la interpretacion para los lotes
      const idinter = new Set(lotes.map((lote) => lote.field_crop_1))
      setIdinter([...idinter])
      // obteniendo los analisis
      const analisis = await getAnalysisListApi(finca.nid)
      // obteniendo las interpretaciones
      const inter = await Promise.all(
        analisis.map(async (analisis, i) => {
          const interpretacion = await getInterpretationApi(analisis.nid)
          const fielCrop = analisis.field_crop
          return [fielCrop, interpretacion]
        })
      )
      setInterpre(inter)
      // obteniendo los el centro del mapa
      const rawBounds = farmBounds(JSON.parse(finca.field_array_sentinel))
      setNewCenter({
        lat: (rawBounds.north + rawBounds.south) / 2,
        lng: (rawBounds.east + rawBounds.west) / 2
      })
      // obtenemos el arreglo necesario para la nueva api de los lotes
      setStringLand(
        lotes.map((lote) => [
          lote.nid,
          formatCoordinates(
            JSON.parse(
              lote.field_outlineland.replace(
                /(['"])?([a-z0-9A-Z_]+)(['"])?:/g,
                '"$2": '
              )
            )
          )
        ])
      )
      // obtenemos el arreglo necesario para la nueva api de la finca
      setStringFarm(
        formatCoordinates(
          JSON.parse(
            finca.field_geojson_outline.replace(
              /(['"])?([a-z0-9A-Z_]+)(['"])?:/g,
              '"$2": '
            )
          )
        )
      )
      const arraySentinel = JSON.parse(finca.field_array_sentinel)
      const vi = await vegeIndex()
      const indicesSentinel = vi.map((i) => ({
        name: i.name
      }))
      // obteniendo token para sentinel
      // eslint-disable-next-line camelcase
      const {
        res: {
          data: { access_token }
        }
      } = await tokenSentinel()
      const urlsImages = await Promise.all(
        indicesSentinel.map(async (e) => [
          await getIndexFunc(
            arraySentinel,
            reporte.cloudCoverage,
            reporte.dateSentinel,
            access_token,
            e.name
          ),
          e.name
        ])
      )
      setSentinelUrlImage(urlsImages)
      if (reporte) {
        const alto = reporte.imagenMedidas.north - reporte.imagenMedidas.south
        const ancho = reporte.imagenMedidas.east - reporte.imagenMedidas.west

        if (alto > ancho) {
          const porcentaje = (ancho / alto) * 100
          setBaseImageAlto(base)
          setBaseImageAncho(Math.round(base * (porcentaje / 100)))
        } else {
          const porcentaje = (alto / ancho) * 100
          setBaseImageAlto(Math.round(base * (porcentaje / 100)))
          setBaseImageAncho(base)
        }
      }
      setSpinner(false)
    }
    construReporte()
  }, [])
  // estilos de la api de PDF
  const styles = StyleSheet.create({
    contenView: {
      width: '100%',
      height: '100vh',
      display: 'block',
      margin: '2rem auto',
      color: '#243746'
    },
    page: {
      flexDirection: 'column',
      backgroundColor: '#fff',
      textAlign: 'center',
      fontSize: '12px',
      color: '#243746',
      fontfamily: 'Verdana'
    },
    section: {
      padding: 10,
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      width: '75%',
      margin: '0 auto',
      color: '#243746',
      fontfamily: 'Verdana'
    },
    sectionWheater: {
      padding: 10,
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'colum',
      width: '100%',
      margin: '0 auto',
      color: '#243746',
      fontSize: '10px'
    },
    title: {
      fontfamily: 'Verdana',
      fontSize: '16px',
      fontWeight: 'bold',
      margin: '0.5cm 0 0',
      padding: '0 6px 10px',
      borderBottom: '1px solid #3dae2b ',
      textTransform: 'uppercase',
      color: '#243746'
    },
    text: {
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '0.1cm',
      fontSize: '11px',
      width: '100%',
      textAlign: 'justify',
      margin: '1cm auto',
      lineHeight: '1.5'
    },
    table: {
      // textTransform: 'uppercase',
      width: '3.5cm',
      textAlign: 'center',
      borderColor: '#243746',
      // borderWidth: '1px',
      padding: '5px 5px',
      fontSize: '10px',
      fontWeight: 'bold'
    },
    tableTwo: {
      // backgroundColor: 'white',
      width: '5cm',
      textAlign: 'left',
      borderColor: '#243746',
      // borderWidth: '1px',
      borderLeft: 'none',
      padding: '5px 5px',
      fontSize: '10px'
    }
  })
  const color = (e) => {
    if (e === 'bajo') {
      return '#F5796D'
    } else if (e === 'alto') {
      return '#70E388'
    } else {
      return '#F6C26D'
    }
  }
  const bg = (n) => {
    if (n % 2 === 0) {
      return '#f4f7f3'
    } else {
      return '#d9ead3c0'
    }
  }

  return (
    <div className="reporte">
      {spinner ? (
        <Spinner text={'Generando reporte'} />
      ) : (
        <PDFViewer
          showToolbar={true}
          fileName="james"
          style={styles.contenView}
        >
          <Document>
            <Page size="A4" style={styles.page}>
              <View fixed>
                <Image
                  style={{
                    width: '20%',
                    height: 'auto',
                    display: 'inline-block',
                    margin: '2cm auto 0 2cm'
                  }}
                  src={logo}
                ></Image>
              </View>
              <View style={styles.section}>
                <Text style={{ width: '100%', textAlign: 'right' }}>
                  {formatDate()}
                </Text>
              </View>
              {finca && (
                <View style={{ ...styles.section, flexDirection: 'column', padding: '0', margin: '0 auto' }}>
                  <Text style={styles.title}>
                    {finca.field_name} {finca.field_ubication}{' '}
                    {finca.field_hectares} Héctareas
                  </Text>
                  {newCenter && (
                    <View
                      style={{
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        height: '8cm',
                        alignItems: 'center',
                        gap: '0.5cm',
                        margin: '40px'
                      }}
                    >
                      <View style={{ width: '50%' }}>
                        <Image
                          src={stringLand && ulrMap(newCenter, stringFarm)}
                        ></Image>
                      </View>
                      <View style={{ flexDirection: 'column', width: '50%' }}>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: '#f4f7f3',
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px'
                          }}
                        >
                          <Text style={styles.table}>Nombre: </Text>
                          <Text style={styles.tableTwo}>
                            {finca.field_name}
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: '#d9ead3c0'
                          }}
                        >
                          <Text style={styles.table}>Ubicacion: </Text>
                          <Text style={styles.tableTwo}>
                            {finca.field_ubication}
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: '#f4f7f3'
                          }}
                        >
                          <Text style={styles.table}>Hectareas: </Text>
                          <Text style={styles.tableTwo}>
                            {finca.field_hectares}
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: '#d9ead3c0'
                          }}
                        >
                          <Text style={styles.table}>Latitud: </Text>
                          <Text style={styles.tableTwo}>
                            {finca.field_latitude}
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: '#f4f7f3',
                            borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px'
                          }}
                        >
                          <Text style={styles.table}>Longitud: </Text>
                          <Text style={styles.tableTwo}>
                            {finca.field_length}
                          </Text>
                        </View>
                        {/* lo del clima.. */}
                        {weatherF && (
                          <View
                            style={{
                              flexDirection: 'row',
                              // backgroundColor: '#EFFFEC',
                              borderWidth: '1px',
                              borderColor: '#243746',
                              borderRadius: '20px',
                              width: '100%',
                              margin: '20px auto 0',
                              padding: '0'
                            }}
                          >
                            <View style={styles.sectionWheater}>
                              <Image
                                style={{
                                  width: 'auto',
                                  height: '0.8cm',
                                  display: 'inline-block'
                                }}
                                src={temperatureImage}
                              ></Image>
                              <Text style={{ fontSize: '8px' }}>
                                {weatherF.general_data.weather_temperature}
                              </Text>
                              <Text style={{ fontSize: '8px' }}>
                                Temperatura
                              </Text>
                            </View>
                            <View style={styles.sectionWheater}>
                              <Image
                                style={{
                                  width: 'auto',
                                  height: '0.8cm',
                                  display: 'inline-block'
                                }}
                                src={humidityImage}
                              ></Image>
                              <Text style={{ fontSize: '8px' }}>
                                {weatherF.general_data.weather_humidity}
                              </Text>
                              <Text style={{ fontSize: '8px' }}>Humedad</Text>
                            </View>
                            <View style={styles.sectionWheater}>
                              {' '}
                              <Image
                                style={{
                                  width: 'auto',
                                  height: '0.8cm',
                                  display: 'inline-block'
                                }}
                                src={windImage}
                              ></Image>
                              <Text style={{ fontSize: '8px' }}>
                                {weatherF.general_data.weather_wind}
                              </Text>
                              <Text style={{ fontSize: '8px' }}>Vientos</Text>
                            </View>
                            <View style={styles.sectionWheater}>
                              <Image
                                style={{
                                  width: 'auto',
                                  height: '0.8cm',
                                  display: 'inline-block'
                                }}
                                src={rainImage}
                              ></Image>
                              <Text style={{ fontSize: '8px' }}>
                                {weatherF.general_data.weather_rainProbability}
                              </Text>
                              <Text style={{ fontSize: '8px' }}>
                                Probabilidad
                              </Text>
                            </View>
                          </View>
                        )}
                      </View>
                    </View>
                  )}
                </View>
              )}
              {/* información del map e info general */}
              <View
                style={{
                  borderWidth: '1px',
                  borderColor: '#243746',
                  borderRadius: '20px',
                  width: '70%',
                  margin: '0 auto',
                  padding: '0 20px'
                }}
              >
                {newCenter && (
                  <View
                    style={{
                      width: '100%',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      height: '10cm',
                      // marginTop: '2cm',
                      alignItems: 'center'
                    }}
                  >
                    <View
                      style={{
                        width: '100%',
                        height: 'auto',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                        // marginTop: '2cm'
                      }}
                    >
                      <Image
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain'
                        }}
                        src={reporte.image2}
                      ></Image>
                    </View>
                  </View>
                )}
              </View>
            </Page>
            {/* información de los lotes */}
            {tiposCultivos &&
              tiposCultivos.map((crop, i) => (
                <Page key={i} size="A4" style={styles.page}>
                  <View fixed>
                    <Image
                      style={{
                        width: '20%',
                        height: 'auto',
                        display: 'inline-block',
                        margin: '2cm auto 0 2cm'
                      }}
                      src={logo}
                    ></Image>
                  </View>
                  {i === 0 && (
                    <View
                      style={{ ...styles.section, flexDirection: 'column' }}
                    >
                      <Text style={styles.title}>Tipos de cultivo</Text>
                    </View>
                  )}
                  <Text
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      margin: '30px auto 20px',
                      textAlign: 'left',
                      width: '70%'
                    }}
                  >
                    {crop}
                  </Text>
                  <View style={{ margin: '0 auto' }}>
                    {lotes.map(
                      (lote, i) =>
                        lote.field_crop === crop && (
                          <View
                            style={{
                              flexDirection: 'row',
                              width: '100%',
                              alignItems: 'flex-start',
                              justifyContent: 'flex-start',
                              gap: '0.5cm',
                              margin: '0.25cm 0'
                            }}
                            key={i}
                          >
                            {newCenter && (
                              <View>
                                <Image
                                  style={{
                                    width: '200px',
                                    height: 'auto',
                                    borderRadius: '10px'
                                  }}
                                  src={
                                    stringLand &&
                                    ulrMap(
                                      newCenter,
                                      stringFarm,
                                      stringLand[i][1]
                                    )
                                  }
                                ></Image>
                              </View>
                            )}
                            <View style={{ gap: '0' }}>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  backgroundColor: '#f4f7f3',
                                  borderTopLeftRadius: '20px',
                                  borderTopRightRadius: '20px'
                                }}
                              >
                                <Text style={styles.table}>Nombre: </Text>
                                <Text style={styles.tableTwo}>
                                  {lote.field_name}
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  backgroundColor: '#d9ead3c0'
                                }}
                              >
                                <Text style={styles.table}>
                                  {lote.field_unidad_area}
                                </Text>
                                <Text style={styles.tableTwo}>
                                  {lote.field_hectares}
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  backgroundColor: '#f4f7f3'
                                }}
                              >
                                <Text style={styles.table}>
                                  Tipo de cultivo:
                                </Text>
                                <Text style={styles.tableTwo}>
                                  {lote.field_crop}
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  backgroundColor: '#d9ead3c0'
                                }}
                              >
                                <Text style={styles.table}>Latitud:</Text>
                                <Text style={styles.tableTwo}>
                                  {lote.field_latitude}
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  backgroundColor: '#f4f7f3',
                                  borderBottomLeftRadius: '20px',
                                  borderBottomRightRadius: '20px'
                                }}
                              >
                                <Text style={styles.table}>Longitud: </Text>
                                <Text style={styles.tableTwo}>
                                  {lote.field_length}
                                </Text>
                              </View>
                            </View>
                          </View>
                        )
                    )}
                  </View>
                  <Text
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      margin: '0.5cm auto',
                      textAlign: 'left',
                      width: '70%'
                    }}
                  >
                    Interpretación
                  </Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      maxWidth: '15cm',
                      margin: '0 auto',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                      fontSize: '10px',
                      borderColor: '#243746',
                      borderWidth: '1px',
                      borderRadius: '10px',
                      columnGap: '1cm',
                      padding: '10px'
                    }}
                  >
                    {idinter &&
                      interpre.map((int, i) =>
                        i === 0
                          ? int[1].map((e, n) => (
                              <View
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'flex-end',
                                  gap: '10px',
                                  width: '3cm'
                                }}
                                key={n}
                              >
                                <Text
                                  style={{
                                    fontSize: '10px',
                                    lineHeight: '1.5'
                                  }}
                                >
                                  {e.name} {e.value}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: '10px',
                                    padding: '1px 2px',
                                    color: 'white',
                                    backgroundColor: color(e.state)
                                  }}
                                >
                                  {' '}
                                  {e.state}{' '}
                                </Text>
                              </View>
                            ))
                          : null
                      )}
                  </View>
                </Page>
              ))}
            <Page size="A4" style={styles.page}>
              <View fixed>
                <Image
                  style={{
                    width: '20%',
                    height: 'auto',
                    display: 'inline-block',
                    margin: '2cm auto 0 2cm'
                  }}
                  src={logo}
                ></Image>
              </View>
              <View style={{ ...styles.section, flexDirection: 'column' }}>
                <Text style={styles.title}>
                Imágenes satelitales Api sentinel hub
                </Text>
              </View>
              {sentinelUrlImage && (
                <View
                  style={{
                    margin: '0 2cm',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    flexWrap: 'wrap'
                  }}
                >
                  {sentinelUrlImage.map((e, i) => (
                    <View
                      style={{
                        padding: '10px',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        borderWidth: '1px',
                        borderColor: '#243746',
                        borderRadius: '20px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '50px 0 0'
                      }}
                      key={i}
                    >
                      <Image
                        style={{
                          maxWidth: '200px',
                          width: baseImageAncho,
                          height: baseImageAlto,
                          maxHeight: '200px'
                        }}
                        src={e[0]}
                      ></Image>
                      <View
                        style={{
                          margin: '20px 0 0',
                          width: '50%',
                          height: '200px',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Text
                          style={{
                            margin: '5px auto 0',
                            textAlign: 'center',
                            color: '#3dae2b',
                            fontSize: '20px'
                          }}
                        >
                          {e[1]}
                        </Text>
                        <Text
                          style={{
                            margin: '5px auto 0',
                            textAlign: 'center',
                            width: '100px'
                          }}
                        >
                          {reporte.dateSentinel}
                        </Text>
                        <Text
                          style={{
                            margin: '5px auto 0',
                            textAlign: 'center',
                            width: '100px'
                          }}
                        >
                          Nubosidad: {reporte.cloudCoverage}%
                        </Text>
                        <Text
                          style={{
                            margin: '15px auto 0',
                            textAlign: 'left',
                            fontSize: '10px'
                          }}
                        >
                          {indexDescripcion[e[1]]}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
              )}
            </Page>
            {/* pronostico del clima */}
            <Page size="A4" style={styles.page}>
              <View fixed>
                <Image
                  style={{
                    width: '20%',
                    height: 'auto',
                    display: 'inline-block',
                    margin: '2cm auto 0 2cm'
                  }}
                  src={logo}
                ></Image>
              </View>
              <View style={{ ...styles.section, flexDirection: 'column' }}>
                <Text style={styles.title}>
                  Pronóstico del clima api meteomatics
                </Text>
              </View>
              {weatherF && (
                <View
                  style={{
                    justifyContent: 'center',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '80%',
                    margin: '30px auto 0',
                    backgroundColor: '#E7F6EC',
                    borderRadius: '20px',
                    overflow: 'hidden'
                  }}
                >
                  <View
                    style={{
                      gap: '10px',
                      fontSize: '10px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      backgroundColor: bg(1),
                      padding: '10px'
                    }}
                  >
                    <Text style={{ width: '1.9cm' }}>Fecha</Text>
                    <Text style={{ width: '1.9cm' }}>Rad</Text>
                    <Text style={{ width: '1.9cm' }}>ET</Text>
                    <Text style={{ width: '1.9cm' }}>GDD</Text>
                    <Text style={{ width: '1.9cm' }}>Humedad</Text>
                    <Text style={{ width: '1.9cm' }}>Lluvia</Text>
                    <Text style={{ width: '1.9cm' }}>SMD</Text>
                    <Text style={{ width: '1.9cm' }}>SMI</Text>
                    <Text style={{ width: '1.9cm' }}>T</Text>
                    <Text style={{ width: '1.9cm' }}>Viento</Text>
                  </View>
                  {weatherF.nextDays_data.map((e, i) => (
                    <View
                      style={{
                        gap: '10px',
                        fontSize: '8px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        padding: '10px',
                        backgroundColor: bg(i)
                      }}
                      key={e}
                    >
                      <Text style={{ width: '1.9cm' }}>{e.date}</Text>
                      <Text style={{ width: '1.9cm' }}>
                        {e.weather_direct_rad}
                      </Text>
                      <Text style={{ width: '1.9cm' }}>
                        {e.weather_evapotranspiration}
                      </Text>
                      <Text style={{ width: '1.9cm' }}>
                        {e.weather_growing_degree_days_accumulated}
                      </Text>
                      <Text style={{ width: '1.9cm' }}>
                        {e.weather_humidity}
                      </Text>
                      <Text style={{ width: '1.9cm' }}>
                        {e.weather_rainProbability}
                      </Text>
                      <Text style={{ width: '1.9cm' }}>
                        {e.weather_soil_moisture_deficit}
                      </Text>
                      <Text style={{ width: '1.9cm' }}>
                        {e.weather_soil_moisture_index}
                      </Text>
                      <Text style={{ width: '1.9cm' }}>
                        {e.weather_temperature}
                      </Text>
                      <Text style={{ width: '1.9cm' }}>{e.weather_wind}</Text>
                    </View>
                  ))}
                </View>
              )}
            </Page>
          </Document>
        </PDFViewer>
      )}
    </div>
  )
}

Reporte.propTypes = {
  farm: array,
  reporte: object
}
export default Reporte
