import swal from 'sweetalert'

const getToken = async () => {
  const req = await fetch(`${process.env.REACT_APP_API}/sentinel/token`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (req.status === 200) {
    const res = await req.json()
    return {
      res
    }
  } else {
    swal('Algo ha salido mal', 'no se pudo obtener el token ', 'error')
  }
}

export default getToken
