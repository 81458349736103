import swal from 'sweetalert'

const loginApi = async (method, body) => {
  const req = await fetch(
    `${process.env.REACT_APP_API}/user/login?_format=json`,
    {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  )
  if (req.status === 200) {
    const res = await req.json()
    window.localStorage.setItem('Token', res.logout_token)
    return {
      res,
      login: true
    }
  } else {
    swal('Algo ha salido mal', 'Verifica tus credenciales', 'error')
  }
}

export default loginApi
