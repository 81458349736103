import swal from 'sweetalert'

const getWeather = async (fId) => {
  const req = await fetch(`${process.env.REACT_APP_API}/read/${fId}/weather-home`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (req.status === 200) {
    const res = await req.json()
    return res
  } else {
    swal('Algo ha salido mal', 'No se pudieron obtener datos del clima', 'error')
  }
}

export default getWeather
