import React, { useContext, useEffect, useState } from 'react'
import './SelectNutrient.scss'
import HiddenShow from '../../atoms/HiddenShow/HiddenShow'

import { func, bool } from 'prop-types'

import { userFarmContex } from '../../../App'

const SelectNutrient = ({ setImgNutrient, setEyeValueSel1, eyeValueSel1, setSpinner, inputDisable }) => {
  const farmContex = useContext(userFarmContex)
  const [listNutrient, setListNutrient] = useState([])
  // defaultValue Selected
  const [selected, setSelected] = useState(1)
  useEffect(() => {
    if (farmContex.length > 0) {
      const arrayView = farmContex[0].view
      const list = JSON.parse(arrayView)
      setListNutrient(list)
    }
    setSelected(1)
  }, [farmContex])
  const handleLand = (e) => {
    setSpinner({ hidden: false, text: 'Obteniendo imagenes fertilidad' })
    setSelected(e.target.value)
    if (e.target.value === '1') {
      setImgNutrient('[]')
    } else {
      const pngNutient = listNutrient.filter(
        (n) => n.field_nutrient === e.target.value
      )
      setImgNutrient(pngNutient[0].field_url)
    }
    setTimeout(() => {
      setSpinner({ hidden: true })
    }, 1000)
  }
  return (
    <div className="select__farm select__eye">
      <div>
        <HiddenShow
          eyeValueSel1={eyeValueSel1}
          setEyeValueSel1={setEyeValueSel1}
        />
        <select
          disabled={inputDisable}
          onChange={(e) => handleLand(e)}
          className="select__farm--select select__eye--select"
          value={selected}
        >
          <option value={1}>Seleccione</option>
          {listNutrient &&
            listNutrient.map((e, i) => (
              <option key={i} value={e.field_nutrient}>
                {e.field_nutrient}
              </option>
            ))}
        </select>
      </div>
    </div>
  )
}

SelectNutrient.propTypes = {
  setImgNutrient: func,
  setEyeValueSel1: func,
  eyeValueSel1: bool,
  setSpinner: func,
  inputDisable: bool
}

export default SelectNutrient
