function validarObjeto (objeto) {
  const keys = Object.keys(objeto)
  for (const key of keys) {
    if (
      !Object.prototype.hasOwnProperty.call(objeto, key) ||
      objeto[key] == null ||
      objeto[key] === ''
    ) {
      return false
    }
  }
  return true
}

export default validarObjeto
