import React from 'react'
import logo from '../../../img/logo.png'
import { Link } from 'react-router-dom'
import './Logo.scss'

const Logo = () => {
  return (
    <div className='logo'>
      <Link to={'/'}>
        <img src={logo} alt='Nitrosoil4.0'/>
      </Link>
    </div>
  )
}

export default Logo
