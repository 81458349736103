import getFarm from '../services/getFarm'
import getLand from '../services/getLand'
import loginApi from '../services/loginApi'

const useLoggin = () => {
  const userLogin = async (body) => loginApi('POST', body)
  const userFarm = async (uid) => getFarm(uid)
  const userLand = async (nid) => getLand(nid)
  return {
    userLogin,
    userFarm,
    userLand
  }
}

export default useLoggin
